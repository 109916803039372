// src/components/InfluencerCard.tsx
import React, { useContext, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { AiOutlineUserAdd } from "react-icons/ai";
import { MdGroups } from "react-icons/md";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { IoClose } from "react-icons/io5";
import FormField from "./molecules/FormField";
import Button from "./atoms/Button";
import { UserContext } from "../context/UserContext";
import api from "../services/api";
import Swal from "sweetalert2";
import { NumericFormat } from "react-number-format";
import { centsUSDToCentsBRL, formatUSD } from "../utils/formatUSD";

interface InfluencerCardProps {
  name: string;
  subscribers: number;
  previousProfit: string | number;
  operationTerm: string | number;
  estimatedProfit: string | number;
  profileImageSrc?: string;
  value?: string;
  minimumInvestment: number;
  maximumInvestment: number;
  term: number;
  id: number;
  country?: string;
  market?: string;
  bio?: string;
}


// Aplicando a animação de rotação ao Card
const Card = styled.div`
  background: linear-gradient(
    258.96deg,
    rgba(171, 43, 254, 0.49) 0%,
    rgba(128, 11, 203, 0.22) 31.11%,
    rgba(128, 11, 203, 0.2) 74.42%
  );
  /* animation: rotateGradient 30s linear infinite;  */
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  color: white;
  margin: 10px 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ProfileInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const Avatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;

  @media (max-width: 500px) {
    width: 40px;
    height: 40px;
  }
`;

const InvestmentActions = styled.div`
  button {
    background: none;
    border: solid #fff 1px;
    color: #fff;
    border-radius: 6px;
    margin-right: 6px;
    width: 50px;  
  }
`

const NameAndSubscribers = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Name = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (max-width: 400px) {
    width: 90px;
  }
`;

const Subscribers = styled.div`
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 5px;
  p {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: clamp(0.6rem, 2.6vw, 1rem);
  }

  @media (max-width: 370px) {
    display: none;
  }
`;

const InfoText = styled.div`
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 5px;
  p {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: clamp(0.6rem, 2.6vw, 1rem);
  }

`;

const SubscribeButton = styled.button`
  display: flex;
  background: linear-gradient(90deg, #00ff95, #12ca7d);
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  box-shadow: 0px 4px 10px rgba(0, 255, 149, 0.3);

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 500px) {
    padding: 5px;
    font-size: 0.8rem;
  }
`;

const MoneyValue = styled.div`
  color: #00ff95;
`;

const Stats = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
`;

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const StatLabel = styled.div`
  font-size: 0.7rem;
  color: #cccccc;
  width: 100%;
  text-align: center;
`;

const StatValue = styled.div`
  display: flex;
  gap: 5px;
  font-size: 0.9rem;
  font-weight: bold;
 
`;

const Plus = styled.span`
  color: #00ff95;
`;

const Separator = styled.div`
  width: 1px;
  background: linear-gradient(180deg, rgba(162, 191, 254, 0.5), #800bcb);
  margin: 0 10px;
`;

const TextModal = styled.p`
  font-size: 0.9rem;
  color: #fff;
  opacity: 0.8;
  margin-top: 10px;
  b {
  color: #fff;
  }
`;

const SpacedDiv = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  margin-bottom: 8px;
`;

const BalanceContainer = styled.div`
  display: flex;
`;

const TraderDialogCard = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfluencerCard: React.FC<InfluencerCardProps> = ({
  name,
  subscribers,
  previousProfit,
  operationTerm,
  estimatedProfit,
  profileImageSrc,
  value,
  maximumInvestment,
  minimumInvestment,
  term,
  id,
  country,
  market,
  bio
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [investment, setInvestment] = useState<any>();
  const [loading, setLoading] = useState(false)
  const { formattedBalance, decodedToken, refreshBalance } =
    useContext(UserContext);
  const handleChange = (e: string) => {
    const numericValue = e.replace(/[^0-9\.]+/g, "");

    setInvestment(numericValue);
  };
  const handleSubmit = async () => {
    try {
      setLoading(true)
      if (!investment) {
        return Swal.fire("Error", "Add an investment!", "error");
      }

      const endDate = new Date();
      endDate.setDate(endDate.getDate() + term);

      await api.post("traders/transfer", {
        userId: decodedToken.id,
        traderId: id,
        //centavos de dolar, para centavos BRL
        amount: centsUSDToCentsBRL(parseInt(investment) * 100),
        startDate: new Date().toISOString(),
        endDate,
      });
      refreshBalance();
      setOpenModal(false)
      Swal.fire({
        title: "Success",
        text: "Success!",
        icon: "success",
        background: "#000", // Fundo preto
        color: "#fff", // Texto branco
        confirmButtonColor: "#00ff95", // Botão verde
        iconColor: "#00ff95", // Cor do ícone
      });

    } catch (error) {
      const err = error as any;
      Swal.fire({
        title: "Error",
        text: err.response.data.message || "Something went wrong.",
        icon: "error",
        background: "#000", // Fundo preto
        color: "#fff", // Texto branco
        confirmButtonColor: "#ff4d4f", // Botão vermelho
        iconColor: "#ff4d4f", // Cor do ícone
      });

    } finally {
      setLoading(false)
    }
  };
  return (
    <>
      <Card>
        <Header>
          <ProfileInfo>
            <Avatar src={profileImageSrc} alt={`${name}'s avatar`} />
            <NameAndSubscribers>
              <Name>{name}</Name>
              <InfoText>Country: {country}</InfoText>
              <InfoText>Market: {market} </InfoText>

              {value === undefined ? (
                <Subscribers>
                  {/* <p>
                    <Plus>+</Plus> de {subscribers} investidores <MdGroups />
                  </p> */}
                </Subscribers>
              ) : (
                <></>
              )}
            </NameAndSubscribers>
          </ProfileInfo>
          {value === undefined ? (
            <SubscribeButton onClick={() => setOpenModal(true)}>
              Invest <AiOutlineUserAdd />
            </SubscribeButton>
          ) : (
            <MoneyValue>
              <h3> {value}</h3>
            </MoneyValue>
          )}
        </Header>
        <Stats>
          <Stat>
            <StatLabel>Profit rate Previous</StatLabel>
            <StatValue>
              <Plus>+</Plus> {previousProfit}%
            </StatValue>
          </Stat>
          <Separator />
          <Stat>
            <StatLabel>Operation term</StatLabel>
            <StatValue>
              {operationTerm}
            </StatValue>
          </Stat>
          <Separator />
          <Stat>
            <StatLabel>Estimated Profit</StatLabel>
            <StatValue>
              <Plus>+</Plus> {estimatedProfit}%
            </StatValue>
          </Stat>
        </Stats>
        <Stats>
          <Stat>
            <StatLabel>Duration</StatLabel>
            <StatValue>
              {term} {'(Days)'}
            </StatValue>
          </Stat>
          <Separator />
          <Stat>
            <StatLabel>Min Investment:</StatLabel>
            <StatValue>
              {formatUSD(Number(minimumInvestment))}
            </StatValue>
          </Stat>
          <Separator />
          <Stat>
            <StatLabel>Max Investment:</StatLabel>
            <StatValue>
              {formatUSD(Number(maximumInvestment))}
            </StatValue>
          </Stat>
        </Stats>
      </Card>
      <Dialog
        open={openModal}
        fullWidth
        maxWidth="lg"
        onClose={() => setOpenModal(false)}
        aria-labelledby="investment-dialog-title"
        aria-describedby="investment-dialog-description"
        sx={{
          zIndex: 10,
          ".MuiPaper-root": {
            backgroundColor: "#1c1c1c",
            color: "#f5f5f5",
            borderRadius: "12px",
            padding: "20px",
          },
        }}
      >
        <DialogTitle
          id="investment-dialog-title"
          sx={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            borderBottom: "1px solid #444",
            paddingBottom: "10px",
          }}
        >
          Investment
          <IconButton
            aria-label="close"
            onClick={() => setOpenModal(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#f5f5f5",
            }}
          >
            <IoClose />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "20px" }}>
          <BalanceContainer></BalanceContainer>
          <TraderDialogCard>
            <ProfileInfo
              style={{
                background:
                  "linear-gradient(90deg, rgb(171 43 254 / 40%) 0%, transparent 80%)",
                borderRadius: "50px",
                padding: 5,
                marginTop: '20px',
                width: '100%',
                marginBottom: '20px'
              }}
            >

              <Avatar src={profileImageSrc} alt={`${name}'s avatar`} />
              <NameAndSubscribers>
                <Name>{name}</Name>
              </NameAndSubscribers>
            </ProfileInfo>
            <p>{bio}</p>
            <hr />
          </TraderDialogCard>

          <TextModal>Available Balance: <b>{formattedBalance}</b> USD</TextModal>
          <TextModal></TextModal>

          <div>
            <TextModal>Investment Amount</TextModal>
            <NumericFormat
              value={investment}
              prefix={"$"}
              allowNegative={false}
              customInput={FormField}
              thousandSeparator="."
              decimalSeparator=","
              onValueChange={(e) => {
                handleChange(e.value);
              }}
              placeholder={`Minimum investment of ${formatUSD(minimumInvestment)} USDT`}
              style={{ border: "2px solid #fff" }}
            />
            <InvestmentActions>
              <InvestmentActions>
                <button onClick={() => setInvestment(minimumInvestment / 100)}>
                  Min
                </button>
                <button onClick={() => setInvestment(maximumInvestment / 100)}>
                  Max
                </button>
              </InvestmentActions>


            </InvestmentActions>

            <SpacedDiv>
              <TextModal>Minimum Investment</TextModal>
              <TextModal>{formatUSD(minimumInvestment)} USDT</TextModal>
            </SpacedDiv>
            <SpacedDiv>
              <TextModal>Maximum Investment</TextModal>
              <TextModal>{formatUSD(maximumInvestment)} USDT</TextModal>
            </SpacedDiv>
            <Button isLoading={loading} variant="gradient" size="large" onClick={handleSubmit}>
              Invest
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InfluencerCard;
