import React, { useEffect, useState } from "react";
import {
  LevelContainer,
  LevelCard,
  LevelBody,
  ProgressCircle,
  LevelBadgeContainer,
  LevelRow,
  LevelInfo,
} from "./LevelSection.styles";
import { FaCheck, FaUserFriends } from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import api from "../../services/api";
import Swal from "sweetalert2";
import {
  Skeleton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableFooter,
} from "@mui/material";

type ChildLevel1 = {
  email: string;
  username: string;
  phone: string;
  createdAt: string;
  balance: boolean;
};

type AffiliatesKeys = `childLevel${1 | 2 | 3 | 4 | 5}`;

type AffCountType = Record<AffiliatesKeys, number>;
type Affiliates = {
  requestedChild: ChildLevel1[];
  count: number; // Total de registros para paginação
};

const calculateLevel = (totalAffiliates: number): number => {
  if (totalAffiliates >= 100) return 5;
  if (totalAffiliates >= 50) return 4;
  if (totalAffiliates >= 35) return 3;
  if (totalAffiliates >= 15) return 2;
  if (totalAffiliates >= 5) return 1;
  return 0;
};

const levels = [5, 15, 35, 50, 100];
const calculateProgressPercentage = (
  allAffiliates: number,
  currentLevel: number
) => {
  if (currentLevel < 1 || currentLevel > levels.length) {
    return 0;
  }

  const nextLevelRequirement = levels[currentLevel + 1];
  return Math.min((allAffiliates / nextLevelRequirement) * 100, 100);
};

const LevelSection = () => {
  const [affiliates, setAffiliates] = useState<Affiliates | null>(null);
  const [statsAffiliates, setStatsAffiliates] = useState<{
    currentLvl: number;
    allAffCount: number;
  }>({ currentLvl: 0, allAffCount: 0 });
  const [selectedLevel, setSelectedLevel] = useState<number | null>(null);
  const [affiliateCount, setAffiliateCount] = useState<AffCountType>();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);

  useEffect(() => {
    const init = async () => {
      try {
        const res = await api.get("/user/hierarchy");
        const data = res.data;

        const totalAffiliates = Object.keys(data).reduce((prev, curr) => {
          prev += data[curr];
          return prev;
        }, 0);

        setAffiliateCount(data);
        setStatsAffiliates({
          currentLvl: calculateLevel(totalAffiliates),
          allAffCount: totalAffiliates,
        });
      } catch (e) {}
    };
    init();
  }, []);

  const fetchAffiliates = async (
    level: number,
    page: number,
    rowsPerPage: number
  ) => {
    try {
      setLoading(true);
      const skip = page * rowsPerPage;
      const take = rowsPerPage;

      const res = await api.get(
        `/user/hierarchy/${level}?skip=${skip}&take=${take}`
      );
      const { count, requestedChild } = res.data;
      setAffiliates({ count, requestedChild });
    } catch (e) {
      Swal.fire(
        "Erro",
        `An error occurred when trying to search for affiliates on the level ${level}`,
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleLevelClick = (level: number) => {
    setSelectedLevel(level === selectedLevel ? null : level);
    setPage(0); // Reseta para a primeira página ao mudar de nível
    fetchAffiliates(level, 0, rowsPerPage);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
    if (selectedLevel) {
      fetchAffiliates(selectedLevel, newPage, rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reseta para a primeira página ao mudar quantidade por página
    if (selectedLevel) {
      fetchAffiliates(selectedLevel, 0, newRowsPerPage);
    }
  };

  const convertDate = (d: string) => {
    const date = new Date(d);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <LevelContainer>
      <LevelCard>
        <LevelBody>
          <LevelBadgeContainer>
            <p className="mb-2">Current Level</p>
            <ProgressCircle
              percent={`${calculateProgressPercentage(
                statsAffiliates.allAffCount,
                statsAffiliates.currentLvl
              )}`}
            >
              <span>{statsAffiliates.currentLvl}</span>
            </ProgressCircle>
          </LevelBadgeContainer>
        </LevelBody>
        <LevelRow>
          {Array.from({ length: 5 }).map((_, index) => (
            <div
              className="level"
              key={index}
              onClick={() => handleLevelClick(index + 1)}
              style={{
                cursor: "pointer",
                border: selectedLevel === index + 1 ? "2px solid blue" : "",
              }}
            >
              <p>Level {index + 1}</p>
              <p>
                {affiliateCount?.[`childLevel${index + 1}` as AffiliatesKeys]}{" "}
                Affiliates
              </p>
            </div>
          ))}
        </LevelRow>
        {loading ? (
          <Skeleton
            variant="rounded"
            width={"70%"}
            height={200}
            sx={{ bgcolor: "grey.800" }}
          />
        ) : (
          selectedLevel && (
            <div className="affiliates-table" style={{ maxWidth: "100%", width: "100%" }}>
              <h3>Level {selectedLevel} Users</h3>
              <div style={{ overflow: "auto" }}>
                <Table
                  sx={{
                    backgroundColor: "#1e1e1e",
                    ".MuiTableCell-root": { color: "#fff" },
                    borderRadius: "6px",
                    overflow: "auto",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      {selectedLevel ? (
                        <>
                          <TableCell>Email</TableCell>
                          <TableCell>Phone</TableCell>
                          <TableCell>Investments</TableCell>
                        </>
                      ) : null}
                      <TableCell>Register Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {affiliates?.requestedChild.map((user, index) => (
                      <TableRow key={index}>
                        <TableCell>{user.username}</TableCell>
                        {selectedLevel  ? (
                          <>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.phone}</TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {user.balance ? (
                                <FaCheck color="#4CAF50" />
                              ) : (
                                <CgClose color="#FF4C4C" />
                              )}
                            </TableCell>
                          </>
                        ) : null}
                        <TableCell>{convertDate(user.createdAt)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell
                        colSpan={selectedLevel}
                        sx={{
                          width: "100%", // Garante que a célula ocupa toda a largura
                          padding: 0, // Remove padding que pode causar overflow
                        }}
                      >
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <TablePagination
                            component="div"
                            count={affiliates?.count || 0}
                            page={page}
                            rowsPerPageOptions={[30, 50, 100, 200]}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{
                              minWidth: "100%",
                              width: "100%",
                              backgroundColor: "#1e1e1e",
                              color: "#fff",
                              svg: { color: "#fff" },
                              borderRadius: "6px",
                            }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </div>
            </div>
          )
        )}
        <LevelInfo>
          <p>
            <FaUserFriends /> Número total de afiliados:{" "}
            <strong>{statsAffiliates.allAffCount}</strong>
          </p>
        </LevelInfo>
      </LevelCard>
    </LevelContainer>
  );
};

export default LevelSection;
