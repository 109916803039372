import styled from "styled-components";
import bgImage from "../../images/square-bg.jpg";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.body};
  min-height: 100vh;
  padding-bottom: 2rem;
  color: ${({ theme }) => theme.text};
`;

export const HeaderSection = styled.div`
  background-color: ${({ theme }) => theme.primaryColor};
  padding: 1rem 0;
  text-align: center;
  color: #fff;
  font-weight: bold;
`;

export const CarouselContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const CarouselSlide = styled.div`
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  transition: transform 0.5s ease-in-out;
   width: 100%;
`;

export const Card = styled.div`
  background-image: url(${bgImage});
  background-size: cover;
  background-position: center;
  border-radius: 6px;
  padding: 20px;
  position: relative;
  text-align: center;
  color: ${({ theme }) => theme.text};
  min-height: 200px;
  width: 100%;
  box-shadow: 0px 4px 10px ${({ theme }) => theme.shadow};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    z-index: 4;
  }
`;

export const CardOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(24deg, #400565, #400565, #400565d4);
  z-index: 1;
`;

export const Title = styled.h3`
  font-size: 1rem;
  margin-bottom: 1rem;
  z-index: 2;
  position: relative;
  color: #fff;
  text-align: left;
`;

export const CarouselLines = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

export const Line = styled.div<{ active: boolean }>`
  width: ${({ active }) => (active ? "50px" : "30px")};
  height: 5px;
  margin: 0 5px;
  background-color: ${({ active, theme }) =>
    active ? theme.primaryColor : theme.boxBackground};
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.primaryColor};
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
  
`;

export const IconButton = styled.button`
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: linear-gradient(24deg, #400565, #400565, #400565d4);

  &:hover {
    background-color: ${({ theme }) => theme.hover};
  }

  svg {
  width: 170px;
  }
`;

export const CopyCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0.5rem 0.8rem;
  background-color: ${({ theme }) => theme.boxBackground};
  border-radius: 8px;
  flex-direction: column;
  p {
    font-size: 0.8rem;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.text};
    font-weight: 500;
  }

 div {
  display: flex;
  gap: 10px;
 }

  button {
    background-color: ${({ theme }) => theme.primaryColor};
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.hover};
    }
  }
`;

export const CardCopyCode = styled.div`
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  padding: 20px;
  position: relative;
  text-align: center;
  color: ${({ theme }) => theme.text};
  min-height: 200px;
  width: 100%;
  box-shadow: 0px 4px 10px ${({ theme }) => theme.shadow};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CardRow = styled.div`
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  padding: 20px;
  position: relative;
  text-align: center;
  color: ${({ theme }) => theme.text};
  min-height: 200px;
  width: 90%;
  box-shadow: 0px 4px 10px ${({ theme }) => theme.shadow};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;


