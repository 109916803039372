import React, { useState } from "react";
import styled from "styled-components";
import { AsYouType, parsePhoneNumberFromString, getCountryCallingCode, CountryCode } from "libphonenumber-js";
import { countries } from "../../utils/countrys";

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
`;

const StyledSelect = styled.select`
  padding: 10px;
  font-size: 16px;
  border: 2px solid ${({ theme }) => theme.primaryColor};
  border-radius: 5px 0 0 5px;
  background-color: #000;
  color: #fff;
  appearance: none;
  cursor: pointer;
  outline: none;
  max-width: 110px;
  &:focus {
    border-color: ${({ theme }) => theme.primaryColor};
  }
`;

const StyledInput = styled.input<{ hasError: boolean }>`
  width: 100%;
  flex: 1;
  padding: 10px;
  border-radius: 0 5px 5px 0;
  border: 2px solid ${({ hasError, theme }) => (hasError ? "#ff4d4f" : theme.primaryColor)};
  background-color: transparent;
  color: ${({ theme }) => theme.text};
  font-size: 16px;
  transition: border-color 0.3s, color 0.3s;

  ::placeholder {
    color: #a0a0a0;
  }

  &:focus {
    border-color: ${({ theme, hasError }) => (hasError ? "#ff4d4f" : theme.primaryColor)};
    outline: none;
  }
`;

const ErrorText = styled.p`
  color: #ff4d4f;
  font-size: 12px;
  margin: 5px 0 0;
`;

const InputPhone: React.FC<{
  placeholder: string;
  value: string;
  error?: string;
  onChange: (value: string) => void;
}> = ({ placeholder, value, error, onChange }) => {
  const [countryCode, setCountryCode] = useState<CountryCode>("BR"); // Padrão: Brasil

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountryCode = e.target.value as CountryCode;
    setCountryCode(selectedCountryCode);

    // Atualizar o número com o novo código do país
    const parsedNumber = parsePhoneNumberFromString(value || "", countryCode);
    const newNumber = parsedNumber?.nationalNumber || ""; // Extrai o número nacional
    onChange(`+${getCountryCallingCode(selectedCountryCode)} ${newNumber}`);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawInput = e.target.value;
    const formattedNumber = new AsYouType(countryCode).input(rawInput);
    onChange(`+${getCountryCallingCode(countryCode)} ${formattedNumber}`);
  };

  return (
    <div>
      <InputWrapper>
        <StyledSelect value={countryCode} onChange={handleCountryChange}>
          {countries.map((country) => (
            <option key={country.code} value={country.code}>
              {country.name} (+{country.dialCode})
            </option>
          ))}
        </StyledSelect>
        <StyledInput
          type="tel"
          placeholder={placeholder}
          value={value.replace(`+${getCountryCallingCode(countryCode)}`, "").trim()}
          hasError={!!error}
          onChange={handlePhoneChange}
        />
      </InputWrapper>
      {error && <ErrorText>{error}</ErrorText>}
    </div>
  );
};

export default InputPhone;
