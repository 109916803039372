// src/components/organisms/HeaderLogged.tsx
import React, { useContext } from "react";
import styled from "styled-components";
import UserProfile from "./organisms/UserProfile";
import { UserContext } from "../context/UserContext";

interface IHeaderLogged {
  nameScreen?: string; // Agora `nameScreen` é obrigatório
}

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 35px;
  background: linear-gradient(180deg, #800bcb 0%, #400565 80.46%);
  color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  h2 {
    font-size: 1rem;
  }
`;

const HeaderLogged: React.FC<IHeaderLogged> = ({ nameScreen }) => {
  const {decodedToken, formattedBalance} = useContext(UserContext);

  return (
    <HeaderContainer>
      <h2>{nameScreen}</h2>
      <UserProfile name={decodedToken?.name} balance={formattedBalance} />
    </HeaderContainer>
  );
};

export default HeaderLogged;
