import api from './api';

// Tipagem dos dados do usuário
export interface User {
  id: string;
  username: string;
  password?: string;
  pinCode: string;
  email: string;
  name: string;
  phoneNumber: string;
  type: string;
  country: string;
  referredBy?: string;
  avatar?: string[];
  active: boolean;
  createdAt: string;
}

// Função para obter todos os usuários
export const fetchUsers = async (): Promise<User[]> => {
  try {
    const response = await api.get('/users');
    return response.data;
  } catch (error: any) {
    console.error("Erro ao buscar usuários:", error.response?.data || error.message);
    throw new Error(error.response?.data?.message || "Erro ao buscar usuários.");
  }
};

// Função para obter usuários referidos por um determinado usuário
export const fetchReferralUsers = async (referredBy: string): Promise<User[]> => {
  try {
    const response = await api.get(`/users/referrals/${referredBy}`);
    return response.data;
  } catch (error: any) {
    console.error("Erro ao buscar usuários referidos:", error.response?.data || error.message);
    throw new Error(error.response?.data?.message || "Erro ao buscar usuários referidos.");
  }
};

// Função para obter a hierarquia de um usuário
// Função para obter a hierarquia de um usuário
export const fetchUserHierarchy = async (id: number): Promise<User[]> => {
  try {
    const response = await api.get(`/user/hierarchy`, {
      headers: {
        id: id.toString(), // Inclui o ID no cabeçalho
      },
    });
    return response.data;
  } catch (error: any) {
    console.error("Erro ao buscar hierarquia do usuário:", error.response?.data || error.message);
    throw new Error(error.response?.data?.message || "Erro ao buscar hierarquia do usuário.");
  }
};


// Função para criar um novo usuário
export const createUser = async (userPayload: User): Promise<User> => {
  try {
    const response = await api.post('/users', userPayload);
    return response.data;
  } catch (error: any) {
    console.error("Erro ao criar usuário:", error.response?.data || error.message);
    throw new Error(error.response?.data?.message || "Erro ao criar usuário.");
  }
};

// Função para atualizar um usuário
export const updateUser = async (userId: string, userPayload: Partial<User>): Promise<User> => {
  try {
    const response = await api.put(`/users/${userId}`, userPayload);
    return response.data;
  } catch (error: any) {
    console.error("Erro ao atualizar usuário:", error.response?.data || error.message);
    throw new Error(error.response?.data?.message || "Erro ao atualizar usuário.");
  }
};

// Função para deletar um usuário
export const deleteUser = async (userId: string): Promise<void> => {
  try {
    await api.delete(`/users/${userId}`);
  } catch (error: any) {
    console.error("Erro ao deletar usuário:", error.response?.data || error.message);
    throw new Error(error.response?.data?.message || "Erro ao deletar usuário.");
  }
};
