import styled from "styled-components";

export const LevelContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 10px ${({ theme }) => theme.shadow};
`;

export const LevelHeader = styled.div`
  margin-bottom: 30px;
  margin-left: 15px;
  h4 {
    font-size: 1.6rem;
    font-weight: bold;
    color: ${({ theme }) => theme.text};
    text-align: left;
  }
`;

export const LevelCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const LevelBody = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  .level-details {
    text-align: left;
    border-left: 1px solid #fff;
    padding-left: 18px;
    height: 160px;
    background: linear-gradient(87deg, #4005659c, transparent);
    width: 50%;
    strong {
      font-size: 0.8rem;
    }
    p {
      margin: 10px 0;
      color: ${({ theme }) => theme.text};
      font-size: 1rem;

      &.level-name {
        font-size: 1.2rem;
        font-weight: bold;
        color: #fff;
      }
    }
  }
`;

export const LevelBadgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 20px;
  height: 100%;
  min-height: 160px;
  p {
    margin-top: 15px;
    font-size: 1.1rem;
    font-weight: bold;
    color: ${({ theme }) => theme.text};
  }
`;

export const ProgressCircle = styled.div<{percent: string;}>`
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: conic-gradient(
    ${({ theme, percent }) => `${theme.primaryColor} ${percent}%`} ,
    ${({ theme }) => theme.boxBackground} 25%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px ${({ theme }) => theme.primaryColor};

  span {
    font-size: 2rem;
    font-weight: bold;
    color: #9d00ff;
    background: ${({ theme }) => theme.body};
    width: 80%;
    height: 80%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px ${({ theme }) => theme.primaryColor};
  }
`;

export const LevelRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  overflow: auto;
  margin-top: 20px;
  width: 100%;
  vertical-align: midlle;
  .level {
    flex: 1 0 18%;
    text-align: center;
    padding: 10px;
    background-color: ${({ theme }) => theme.boxBackground};
    border-radius: 8px;
    margin: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    p {
      font-size: 0.8rem;
      color: ${({ theme }) => theme.text};
      font-weight: bold;
    }
  }
`;

export const ViewMoreButton = styled.button`
  background-color: ${({ theme }) => theme.primaryColor};
  color: #fff;
  border: none;
  padding: 0.3rem 1.2rem;
  border-radius: 5px;
  font-size: 0.7rem;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: ${({ theme }) => theme.hover};
  }
`;

export const LevelInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  p {
    flex: 1 1 45%;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0;
    color: ${({ theme }) => theme.text};
    font-size: 0.95rem;

    strong {
      font-weight: bold;
      color: #fff;
      font-size: 1.2rem;
    }

    svg {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.primaryColor};
    }
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  td,th {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
`;
