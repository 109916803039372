// src/pages/WithdrawalPage.tsx
import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Tabs from "../../components/molecules/Tabs";
import Button from "../../components/atoms/Button";
import FormField from "../../components/molecules/FormField";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderLogged from "../../components/HeaderLogged";
import TransactionCard from "../../components/TransactionCard";
import { FaUniversity } from "react-icons/fa";
import { MdOutlinePix } from "react-icons/md";
import { NumericFormat } from "react-number-format";
import api from "../../services/api";
import Swal from "sweetalert2";
import { UserContext } from "../../context/UserContext";
import WithdrawalTable from "./WithdrawalTable";

const Container = styled.div`
  background-color: #000;
  min-height: 100vh;
  color: white;
  margin-bottom: 20px;
`;

const BackIcon = styled(AiOutlineArrowLeft)`
  font-size: 1.5rem;
  cursor: pointer;
  margin-right: 10px;
  color: #b08cff;
`;

const TabSection = styled.div`
  margin-top: 1rem;
`;

const AccountSection = styled.div`
  background-color: #1a1a1a;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ExceptionSection = styled.div`
  span:nth-child(1) {
    color: #00aeff;
  }
  span:nth-child(2) {
    color: #fff;
  }
  span:nth-child(3) {
    color: #ffffff85;
  }
`;

const ToReceive = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  span {
    color: #00ff95;
  }
`;

const PixButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: linear-gradient(180deg, rgba(34, 4, 54, 0.69) 0%, #220436 100%);
  backdrop-filter: blur(4px);
  box-shadow: 0px 0px 7px 1px #ffffff40;
  color: #fff;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  margin-top: 0.5rem;
`;

const TransactionSection = styled.div`
  background: linear-gradient(
    258.96deg,
    rgba(171, 43, 254, 0.49) 0%,
    rgba(128, 11, 203, 0.22) 31.11%,
    rgba(128, 11, 203, 0.2) 74.42%
  );
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
`;

const ActionButton = styled(Button)`
  width: 100%;
  margin-top: 1.5rem;
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ffffff85;
  margin: 14px 0px;
  border-radius: 5px;
`;

const WithdrawalPage: React.FC = () => {
  const DOLLAR_PRICE = 1
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    pixKey: "",
    price: "",
  });

  const reaisConverter = useMemo(() => {
    const centavosPrice = parseFloat(formData.price);
    if (!isNaN(centavosPrice)) {
      return parseFloat((centavosPrice * DOLLAR_PRICE).toFixed(2));
    }
    return 0;
  }, [formData.price]);

  const handleChange = (val: string, name: keyof typeof formData) => {
    setFormData({ ...formData, [name]: val });
  };

  const tabOptions = [
    { name: "Withdrawal", action: () => navigate("/withdrawal") },
    { name: "Deposits", action: () => navigate("/deposit") },
  ];

  const handleTabChange = (index: number) => {
    setActiveTab(index);
    tabOptions[index].action();
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await api.post("/user/withdraw", {
        pixKey: formData.pixKey,
        amount: reaisConverter * 100,
      });
      const res = await api.get("/user/info");

      setFormData({ pixKey: "", price: "" });
      Swal.fire(
        "Success!",
        "We will analyze your request within a few working days!",
        "success"
      );
      //armengue remover
      window.location.reload()
    } catch (e) {
      Swal.fire(
        "Erro",
        "An error occurred while trying to withdraw the money",
        "error"
      );
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { }, [handleSubmit])

  return (
    <Container className="container-fluid">
      <HeaderLogged />
      <div className="container mt-4">
        <div className="d-flex align-items-center">
          <BackIcon onClick={() => navigate("/home")} />
          <h3>Withdrawal</h3>
        </div>
        <div className="row">
          <div className="col-12">
            <TabSection>
              <Tabs
                options={tabOptions}
                styleType="underlined"
                activeTab={activeTab}
                onTabChange={handleTabChange}
              />
            </TabSection>
          </div>
        </div>
        <div className="row mt-3">
          {/* <div className="col-12">
            <PixButton variant="primary">
              <MdOutlinePix />
              PIX 1
            </PixButton>
          </div> */}
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <TransactionSection>
              <NumericFormat
                value={formData.price}
                prefix={"$"}
                allowNegative={false}
                customInput={FormField}
                thousandSeparator="."
                decimalSeparator=","
                onValueChange={(e) => {
                  handleChange(e.value, "price");
                }}
                placeholder="Amount"
                style={{ border: "2px solid #fff" }}
              />
              <FormField
                placeholder="Chave Pix E-mail"
                type="text"
                onChange={(e) => {
                  handleChange(e.target.value, "pixKey");
                }}
                value={formData.pixKey}
                style={{ border: "2px solid #fff" }}
              />
              <ExceptionSection>
                <span>*</span>
                <span> Limit:</span>
                <span> $15 - $10.000</span>
              </ExceptionSection>
              <ExceptionSection>
                <span>*</span>
                <span>8% rate</span>
              </ExceptionSection>
              <div></div>
              {/* <ToReceive>
                <FaUniversity /> A Receber:{" "}
                <span>R$ {reaisConverter.toLocaleString()}</span>
              </ToReceive> */}
              <Line></Line>
              {/* <ExceptionSection>
                <span>*</span>
                <span> Valor patrimonial:</span>
                <span> (1 USDT = 5.75 R$)</span>
              </ExceptionSection> */}
            </TransactionSection>
          </div>
        </div>
        <div className="row mt-4">
          <WithdrawalTable />
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <ActionButton
              variant="primary"
              isLoading={loading}
              onClick={handleSubmit}
            >
              Withdrawal
            </ActionButton>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default WithdrawalPage;
