import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderLogged from "../components/HeaderLogged";
import BottomMenu from "../components/BottomMenu";
import { FaArrowUp, FaArrowDown, FaArrowLeft } from "react-icons/fa";
import { formatUSD } from "../utils/formatUSD";

const PageContainer = styled.div`
  background-color: #000;
  min-height: 100vh;
  padding-bottom: 60px;
  color: white;
`;

const HistoryContainer = styled.div`
  padding: 20px;
`;

const TransactionCard = styled.div`
  background: #1c1c1c;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
`;

const TransactionDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const TransactionTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
`;

const TransactionSubtitle = styled.div`
  font-size: 0.85rem;
  color: #aaa;
`;

const Amount = styled.div<{ isPositive: boolean }>`
  font-size: 1.1rem;
  font-weight: bold;
  color: ${(props) => (props.isPositive ? "#00ff95" : "#ff4d4d")};
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div<{ isPositive: boolean }>`
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.isPositive ? "rgba(0, 255, 149, 0.2)" : "rgba(255, 77, 77, 0.2)"};
  border-radius: 50%;
  width: 32px;
  height: 32px;
`;

const LoadingMessage = styled.p`
  text-align: center;
  color: #aaa;
  font-size: 1.2rem;
  margin-top: 20px;
`;

const HistoryPage: React.FC = () => {
  const location = useLocation();
  const { deposit } = location.state || {}; // Recupera o depósito do estado passado

  const [operations, setOperations] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (deposit) {
      setOperations(deposit.traderOperations || []);
    } else {
      navigate(-1); // Volta se o depósito não foi passado
    }
  }, [deposit, navigate]);

  return (
    <PageContainer>
      <HeaderLogged nameScreen="Trading History" />
      <div className="container">
        <div className="row pt-3 pl-5">
          <div className="col-md-12">
            <FaArrowLeft onClick={() => navigate(-1)} color="#fff" /> Back
          </div>
        </div>
        <HistoryContainer>
          {operations.length > 0 ? (
            operations.map((operation, index) => {
              const profit = operation.profit;
              const formattedProfit = formatUSD(profit);

              return (
                <TransactionCard key={index}>
                  <TransactionDetails>
                    <TransactionTitle>Operation #{index + 1}</TransactionTitle>
                    <TransactionSubtitle>
                      {new Date(operation.createdAt).toLocaleString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </TransactionSubtitle>
                  </TransactionDetails>
                  <Amount isPositive={profit >= 0}>
                    <IconWrapper isPositive={profit >= 0}>
                      {profit >= 0 ? <FaArrowUp /> : <FaArrowDown />}
                    </IconWrapper>
                    {profit >= 0 ? "+" : "-"}{formattedProfit}
                  </Amount>
                </TransactionCard>
              );
            })
          ) : (
            <LoadingMessage>No operations found.</LoadingMessage>
          )}
        </HistoryContainer>
      </div>
      <BottomMenu />
    </PageContainer>
  );
};

export default HistoryPage;


