// FormField.tsx
import React from "react";
import Input from "../atoms/Input";
import { FieldError } from "react-hook-form";

interface InputProps extends React.ComponentPropsWithoutRef<"input"> {
  placeholder?: string;
  type: string;
  error?: FieldError;
}

const FormField = React.forwardRef<HTMLInputElement, InputProps>(
  ({ placeholder, type, error, ...rest }, ref) => {
    return (
      <div>
        <Input
          ref={ref}
          type={type}
          placeholder={placeholder || ""}
          hasError={!!error}
          errorMessage={error ? error.message : ""}
          {...rest}
        />
      </div>
    );
  }
);

export default FormField;
