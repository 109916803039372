import styled from 'styled-components';

// Estilos para o overlay do modal
export const ModalOverlay = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

// Estilos para o conteúdo do modal
export const ModalContent = styled.div`
  background: #000;
  border-radius: 10px;
  width: 90%;
  max-width: 800px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  position: relative;
`;

// Botão de fechar
export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

// Título do modal
export const ModalTitle = styled.h2`
  margin: 0;
  font-size: 1.5rem;
  color: #333;
`;

// Corpo do modal (imagem)
export const ModalBody = styled.div`
  margin: 20px 0;
  img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
`;

// Footer do modal (botão de ação)
// Footer do modal (botão de ação)
export const ModalFooter = styled.div`
  margin-top: 20px;

  button {
    width: 100%; /* 100% de largura */
    padding: 15px 0; /* Botão grande */
    font-size: 1.25rem; /* Texto maior */
    font-weight: bold; /* Texto em negrito */
    background:  linear-gradient(0deg, rgb(128, 11, 203) 0%, rgb(128, 11, 203) 25%, rgb(64, 5, 101) 75%, rgb(128, 11, 203) 100%);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s; /* Transições suaves */

    /* Animação de hover */
    &:hover {
      transform: scale(1.05); /* Aumenta ligeiramente o tamanho */
      box-shadow: 0 4px 15px rgba(0, 123, 255, 0.6); /* Sombra destacada */
    }

    /* Animação de clique */
    &:active {
      transform: scale(0.98); /* Reduz ligeiramente o tamanho */
      box-shadow: 0 2px 10px rgba(0, 123, 255, 0.5); /* Sombra reduzida */
    }
  }
`;
