import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../components/atoms/Button";
import FormField from "../components/molecules/FormField";
import Swal from "sweetalert2";
import { login, registerUser } from "../services/authService";
import InputPhone from "../components/atoms/InputPhone";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useSearchParams } from "react-router-dom";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #000;
  color: #fff;
  padding: 2rem;
`;

const FormWrapper = styled.div`
  background-color: #111;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  width: 100%;
  max-width: 500px;
`;

const Heading = styled.h2`
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 1rem;
  text-align: center;
  span {
    color: #b08cff;
  }
`;

const ErrorText = styled.p`
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 5px;
`;

const RegisterPage: React.FC = () => {
  const [formData, setFormData] = useState({
    id: uuidv4(),
    name: "",
    username: "",
    email: "",
    phone: "",
    password: "",
    retypePassword: "",
    referredBy: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    username: "",
    email: "",
    phone: "",
    password: "",
    retypePassword: "",
  });

  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>,isUsername?: boolean) => {
    const { name, value } = e.target;

    // Remover todos os espaços em branco ao alterar o valor
    if(isUsername){ 
      setFormData({ ...formData, [name]: (value.replace(/\s+/g, "")).toLocaleLowerCase() });
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (value: string) => {
    // Remover espaços no número de telefone
    setFormData({ ...formData, phone: value.replace(/\s+/g, "") });
  };

  const validateForm = () => {
    let valid = true;
    let tempErrors = { ...errors };

    // Verificar nome
    if (!formData.name) {
      tempErrors.name = "Name is required";
      valid = false;
    } else {
      tempErrors.name = "";
    }

    // Verificar nome de usuário
    if (!formData.username) {
      tempErrors.username = "Username is required";
      valid = false;
    } else {
      tempErrors.username = "";
    }
    
    const validUsername = /^[a-zA-Z0-9\-_.]+$/;
    if(!validUsername.test(formData.username)){
      tempErrors.username = "Invalid username. Usernames can only contain letters (a-z), numbers (0-9), hyphens (-), underscores (_), and periods (.). Please try again.";
      valid = false;
    } else {
      tempErrors.username = "";
    }

    // Verificar email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email || !emailPattern.test(formData.email)) {
      tempErrors.email = "Invalid email";
      valid = false;
    } else {
      tempErrors.email = "";
    }

    // Verificar número de telefone
    if (!formData.phone) {
      tempErrors.phone = "Phone number is required";
      valid = false;
    } else {
      tempErrors.phone = "";
    }

    // Verificar senha
    if (formData.password.length < 6) {
      tempErrors.password = "Password must be at least 6 characters";
      valid = false;
    } else {
      tempErrors.password = "";
    }

    // Verificar confirmação de senha
    if (formData.password !== formData.retypePassword) {
      tempErrors.retypePassword = "Passwords do not match";
      valid = false;
    } else {
      tempErrors.retypePassword = "";
    }

    setErrors(tempErrors);
    return valid;
  };

  useEffect(() => {
    // Capturar o referrer da URL se disponível
    const referrer = searchParams.get("referrer");
    const localReferrer = localStorage.getItem("referrer");
    if (referrer) {
      localStorage.setItem("referrer", referrer);
      setFormData((prevData) => ({
        ...prevData,
        referredBy: referrer.replace(/\s+/g, ""), // Remove espaços no referrer
      }));
      return;
    }

    if (localReferrer) {
      setFormData((prevData) => ({
        ...prevData,
        referredBy: localReferrer.replace(/\s+/g, ""),
      }));
      return;
    }

    setFormData((prevData) => ({ ...prevData, referredBy: "atc" }));
  }, [searchParams]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      setLoading(true);
      const formattedphone = `+${formData.phone.replace(/\D/g, "")}`;

      const userData = {
        ...formData,
        phone: formattedphone,
        type: "USER",
        country: "BR",
        createdAt: new Date().toISOString(),
        pin: "1234",
      };

      const response = await registerUser(userData);

      if (response) {
        Swal.fire({
          icon: "success",
          title: "Registration Successful!",
          text: "Your account has been created successfully.",
          showConfirmButton: true,
          confirmButtonText: "Proceed",
        }).then(async () => {
          try {
            await login(formData.email, formData.password);
            navigate("/welcome");
          } catch (loginError) {
            console.error("Login após registro falhou:", loginError);
            Swal.fire({
              icon: "warning",
              title: "Login Failed",
              text: "Registration succeeded, but login failed. Please login manually.",
            });
          }
        });
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      const errorMessage = error.response?.data?.error || "Unknown error occurred";
      Swal.fire({
        icon: "error",
        title: "Registration Failed",
        text: errorMessage,
      });
    }
  };

  return (
    <StyledContainer>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 col-lg-6">
            <FormWrapper>
              <Heading>
                <span>BullFx</span>, Where Your Finances Thrive!
              </Heading>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <FormField
                    name="name"
                    placeholder="Name"
                    type="text"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {errors.name && <ErrorText>{errors.name}</ErrorText>}
                </div>
                <div className="mb-3">
                  <FormField
                    name="username"
                    placeholder="Username"
                    type="text"
                    value={formData.username}
                    onChange={(e) =>  handleChange(e,true)}
                  />
                  {errors.username && <ErrorText>{errors.username}</ErrorText>}
                </div>
                <div className="mb-3">
                  <FormField
                    name="email"
                    placeholder="E-mail"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && <ErrorText>{errors.email}</ErrorText>}
                </div>
                <div className="mb-3">
                  <InputPhone
                    placeholder="Enter your phone number"
                    value={formData.phone}
                    onChange={(value) => setFormData({ ...formData, phone: value })}
                  />

                  {errors.phone && <ErrorText>{errors.phone}</ErrorText>}
                </div>
                <div className="mb-3">
                  <FormField
                    name="password"
                    placeholder="Password"
                    type="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  {errors.password && <ErrorText>{errors.password}</ErrorText>}
                </div>
                <div className="mb-3">
                  <FormField
                    name="retypePassword"
                    placeholder="Retype Password"
                    type="password"
                    value={formData.retypePassword}
                    onChange={handleChange}
                  />
                  {errors.retypePassword && (
                    <ErrorText>{errors.retypePassword}</ErrorText>
                  )}
                </div>
                <Button isLoading={loading} variant="gradient" size="large" type="submit">
                  Register
                </Button>
              </form>
              <p className="text-center mt-3">
                Already have an account?{" "}
                <a href="/login" style={{ color: "#b08cff" }}>
                  Login
                </a>
              </p>
            </FormWrapper>
          </div>
        </div>
      </div>
    </StyledContainer>
  );
};

export default RegisterPage;
