import api from './api';

// Função de registro do usuário
export const registerUser = async (userData: {
  id: string;
  username: string;
  password: string;
  email: string;
  name: string;
  phone: string;
  type: string;
  country: string;
  referredBy: string;
  createdAt: string;
}) => {
  const { referredBy, ...data } = userData;

  try {
    const response = await api.post(`/user/create`, {
      ...data,
      indicatedById:referredBy,
    });
    return response.data; // Retorna dados do registro
  } catch (error: any) {
    console.error("Erro ao registrar usuário:", error.response?.data || error.message);

    // Lançar erro para ser tratado pelo chamador
    throw {
      response: error.response || null,
      message: error.message || "Erro desconhecido durante o registro.",
    };
  }
};


// Função de login do usuário
export const login = async (email: string, password: string) => {
  if (!email || !password) {
    throw new Error("Por favor, preencha todos os campos.");
  }
  localStorage.clear()
  try {
    const response = await api.post('/user/login', { email, password });
    const { token, refreshToken } = response.data;

    // Salvar tokens no localStorage
    localStorage.setItem('accessToken', token);
    // localStorage.setItem('refreshToken', refreshToken);
    
    return response.data;
  } catch (error: any) {
    const status = error.response?.status;

    if (status === 401) {
      throw new Error("Credenciais inválidas. Verifique seu nome de usuário e senha.");
    }

    console.error("Erro no login:", error.response?.data || error.message);
    throw new Error("Erro ao tentar fazer login. Tente novamente mais tarde.");
  }
};


// Função para alterar a senha do usuário
export const updatePassword = async (email: string, currentPassword: string, newPassword: string) => {
  if (!currentPassword || !newPassword) {
    throw new Error("Preencha todos os campos de senha.");
  }

  if (newPassword.length < 8) {
    throw new Error("A nova senha deve ter pelo menos 8 caracteres.");
  }

  try {
    const response = await api.patch(`/user/reset`, {
      email,
      oldPassword:currentPassword,
      password:newPassword,
    });
    return response.data;
  } catch (error: any) {
    console.error("Erro ao atualizar senha:", error.response?.data.error);
    throw new Error(error.response?.data.error || "Erro ao atualizar a senha.");
  }
};



// Função para deslogar o usuário
export const logout = () => {
  if (window.confirm("Você tem certeza que deseja sair?")) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    window.location.reload();
  }
};