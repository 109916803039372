import { jwtDecode } from "jwt-decode";
import { createContext, useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../services/api";
import { formatUSD } from "../utils/formatUSD";
interface IDecodedToken {
  email: string;
  id: number;
  username: string;
  iat: number;
  exp: number;
  name: string;
  balance: number;
}

type AuthContextData = {
  decodedToken: IDecodedToken;
  setDecodedToken: React.Dispatch<React.SetStateAction<IDecodedToken>>;
  formattedBalance: string;
  changeToken: (token: string) => void;
  setBalance: React.Dispatch<React.SetStateAction<number | undefined>>;
  refreshBalance: () => Promise<void>;
  signOut(): void;
};

export const UserContext = createContext({} as AuthContextData);

const initToken = {
  id: 0,
  username: "",
  exp: 0,
  iat: 0,
  email: "",
  name: "",
  balance: 0,
};

export function UserProvider() {
  const [decodedToken, setDecodedToken] = useState<IDecodedToken>(
    localStorage.getItem("accessToken")
      ? jwtDecode(localStorage.getItem("accessToken")!)
      : initToken
  );
  const [balance, setBalance] = useState<number>();

  const refreshBalance = async () => {
    try {
      const res = await api.get("/user/info");
      setBalance(res.data.balance.amount);
    } catch {}
  };

  const navigate = useNavigate();
  const authChannel = new BroadcastChannel("auth");
  const messageReceiver = new BroadcastChannel("auth");
  useEffect(() => {
    refreshBalance();

    messageReceiver.onmessage = (message) => {
      switch (message.data) {
        case "signOut":
          localStorage.removeItem("accessToken");
          setDecodedToken(initToken)
          navigate("/login");
          break;
        default:
          break;
      }
    };
  }, []);

  function signOut() {
    authChannel.postMessage("signOut");
  }

  const changeToken = (token: string) => {
    if (!token || token === "undefined") {
      return;
    }
    localStorage.setItem("accessToken", token);
    setDecodedToken(jwtDecode(token));
  };

  return (
    <UserContext.Provider
      value={{
        decodedToken,
        setDecodedToken,
        changeToken,
        formattedBalance: balance
          ? formatUSD(balance) // Chamando a função diretamente
          : formatUSD(decodedToken.balance), // Chamando a função diretamente
        setBalance,
        refreshBalance,
        signOut
      }}
    >
      <Outlet />
    </UserContext.Provider>
  );
}
