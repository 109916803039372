import React from 'react';
import {
  ModalOverlay,
  ModalContent,
  CloseButton,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from './Modal.styles';

// Props do componente Modal
interface ModalProps {
  isOpen: boolean; // Controla a visibilidade do modal
  imageUrl?: string; // URL opcional para exibir a imagem no body
  title: string; // Título do modal
  buttonText: string; // Texto do botão no footer
  onAction: () => void; // Função chamada ao clicar no botão
  onClose: () => void; // Função chamada para fechar o modal
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  imageUrl,
  title,
  buttonText,
  onAction,
  onClose,
}) => {
  return (
    <ModalOverlay isVisible={isOpen}>
      <ModalContent>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <ModalTitle>{title}</ModalTitle>
        <ModalBody>
          {imageUrl && <img src={imageUrl} alt="Modal Content" />}
        </ModalBody>
        <ModalFooter>
          <button onClick={onAction}>{buttonText}</button>
       
        </ModalFooter>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;
