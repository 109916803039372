import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import { lightTheme } from "./styles/themes/light";
import { darkTheme } from "./styles/themes/dark";
import { GlobalStyles } from "./styles/GlobalStyles";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import WelcomePage from "./pages/WelcomePage";
import WithdrawalPage from "./pages/WithdrawalPage";
import DepositPage from "./pages/DepositPage";
import DocPage from "./pages/DocPage";
import MyDeposits from "./pages/MyDeposits";
import SalesPage from "./pages/SalesPage";
import ProfilePage from "./pages/Profile";

import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { UserProvider } from "./context/UserContext";
import ReferralPage from "./pages/ReferralPage/ReferralPage";
import PrivateRoute from "./PrivateRoute";
import Statements from "./pages/Statements";
import HistoryPage from "./pages/HistoryPage";
import Swal from "sweetalert2";
import axios from "axios";

const App: React.FC = () => {
  const [theme, setTheme] = useState("dark");
  const [userIP, setUserIP] = useState<string | null>(null);

  useEffect(() => {
    const getUserIP = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        const userIP = response.data.ip;
        setUserIP(userIP);

        if (!localStorage.getItem("userIP")) {
          localStorage.setItem("userIP", userIP);
        }
      } catch (error) {
        console.error("Erro ao obter o IP do usuário", error);
      }
    };

    const incrementViolationAttempts = () => {
      let attempts = localStorage.getItem("violationAttempts") || "0";
      const newAttempts = parseInt(attempts) + 1;
      localStorage.setItem("violationAttempts", newAttempts.toString());
      return newAttempts;
    };

    const showViolationAlert = () => {
      const attempts = incrementViolationAttempts();
      Swal.fire({
        title: "Atenção!",
        text: `If I were you, I wouldn't attempt anything further. You have already violated our rules ${attempts} times. This is your final warning. Proceeding may result in severe consequences.`,
        icon: "warning",
        confirmButtonText: "Ok",
        background: "#17161A",
        color: "#fff",
        confirmButtonColor: "#FF0000",
      });
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        e.key === "F12" ||
        (e.ctrlKey && e.shiftKey && e.key === "I") ||
        (e.ctrlKey && e.key === "U")
      ) {
        e.preventDefault();
        showViolationAlert();
        return false;
      }
    };

    const handleContextMenu = (e: MouseEvent) => {
      e.preventDefault();
      showViolationAlert();
    };

    getUserIP();
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("contextmenu", handleContextMenu);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  const currentTheme = theme === "light" ? lightTheme : darkTheme;

  return (
    <ThemeProvider theme={currentTheme}>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<SalesPage />} />
          <Route path="/welcome" element={<WelcomePage />} />
          <Route path="/documentation" element={<DocPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/login-alt" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route element={<PrivateRoute />}>
            <Route element={<UserProvider />}>
              <Route path="/statements" element={<Statements />} />
              <Route path="/withdrawal" element={<WithdrawalPage />} />
              <Route path="/deposit" element={<DepositPage />} />
              <Route path="/my-deposits" element={<MyDeposits />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/dashboard" element={<ReferralPage />} />
              <Route path="/history-trader/:id" element={<HistoryPage />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
