import React from "react";
import styled from "styled-components";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { formatUSD } from "../utils/formatUSD";

const HistoryContainer = styled.div`
  padding: 20px;
`;

const TransactionCard = styled.div`
  background: #1c1c1c;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
`;

const TransactionDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const TransactionTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
`;

const TransactionSubtitle = styled.div`
  font-size: 0.85rem;
  color: #aaa;
`;

const Amount = styled.div<{ isPositive: boolean }>`
  font-size: 1.1rem;
  font-weight: bold;
  color: ${(props) => (props.isPositive ? "#00ff95" : "#ff4d4d")};
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div<{ isPositive: boolean }>`
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.isPositive ? "rgba(0, 255, 149, 0.2)" : "rgba(255, 77, 77, 0.2)"};
  border-radius: 50%;
  width: 32px;
  height: 32px;
`;

interface TraderOperationsListProps {
  operations: {
    profit: number;
    createdAt: string;
  }[];
}

const TraderOperationsList: React.FC<TraderOperationsListProps> = ({
  operations,
}) => {

  
  return (
    <HistoryContainer>
      {operations.length > 0 ? (
        operations.map((operation, index) => {
          const profit = operation.profit;
          const formattedProfit = formatUSD(profit);

          return (
            <TransactionCard key={index}>
              <TransactionDetails>
                <TransactionTitle>Operation #{index + 1}</TransactionTitle>
                <TransactionSubtitle>
                  {new Date(operation.createdAt).toLocaleString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </TransactionSubtitle>
              </TransactionDetails>
              <Amount isPositive={profit >= 0}>
                <IconWrapper isPositive={profit >= 0}>
                  {profit >= 0 ? <FaArrowUp /> : <FaArrowDown />}
                </IconWrapper>
                {profit >= 0 ? "+" : "-"}{formattedProfit}
              </Amount>
            </TransactionCard>
          );
        })
      ) : (
        <p>No operations found.</p>
      )}
    </HistoryContainer>
  );
};

export default TraderOperationsList;
