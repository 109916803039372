import React, { useEffect } from "react";
import styled from "styled-components";
import waveImg from "../images/waves.png";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #000;
  color: #fff;
  font-size: 2rem;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #333; // substitui a imagem de fundo por uma cor
  opacity: 0.5;
  z-index: -1;
`;

const TopLeftImg = styled.img`
  position: fixed;
  left: -250px;
  top: -250px;
  width: 700px;
  height: 50%;
  transform: rotate(140deg);
  animation: slideIn 3000ms;
  animation-fill-mode: forwards;

  @keyframes slideIn {
    from {
      height: 50%;
    }

    to {
      height: 70%;
    }
  }
`;

const BottomRightImg = styled.img`
  position: fixed;
  right: -250px;
  bottom: -250px;
  width: 700px;
  height: 50%;
  transform: rotate(330deg);
  animation: slideIn 3000ms;
  animation-fill-mode: forwards;

  @keyframes slideIn {
    from {
      height: 50%;
    }

    to {
      height: 70%;
    }
  }
`;

const WelcomePage: React.FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    const delay = 6.5;
    const timer = setTimeout(() => navigate("/home"), delay * 3);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Container>
      <Background />
      <TopLeftImg src={waveImg} />
      <BottomRightImg src={waveImg} />
      <p>BULL <b>FX</b> .</p>
    </Container>
  );
};

export default WelcomePage;
