export const darkTheme = {
  body: "#121212", // Fundo do corpo
  text: "#FFFFFF", // Texto padrão
  primaryColor: "#400565", // Cor primária (roxo)
  boxBackground: "#2a2a2a", // Fundo de caixas e containers
  shadow: "rgba(0, 0, 0, 0.4)", // Sombra para elementos elevados
  borderColor: "#333333", // Cor de borda para inputs e botões
  inputBackground: "#1e1e1e", // Fundo de inputs
  hover: "#5a00e6", // Cor para hover de botões e links
};
