export const formatUSD = (centavos: number): string => {
  const reais = centavos / 100; // Converte de centavos para reais
  const dolares = reais * 1; // Converte reais para dólares usando a cotação
  const stringReturn =  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(dolares); 
  return stringReturn
};


export const centsUSDToCentsBRL = (centavosUSD: number): number => {
  const dolares = centavosUSD / 100; // Converte de centavos para dólares
  const reais = dolares; // Converte dólares para reais
  return Math.round(reais * 100); // Retorna em centavos de reais
};


export const formatWithDrawUSD = (centavos: number): string => {
  const reais = centavos / 100; 
  const dolares = reais * 1;
  const stringReturn =  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(dolares); 
  return stringReturn
}


