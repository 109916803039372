export const lightTheme = {
  body: "#FFFFFF", // Fundo do corpo
  text: "#363537", // Texto padrão
  primaryColor: "#6a00ff", // Cor primária (roxo)
  boxBackground: "#f4f4f4", // Fundo de caixas e containers
  shadow: "rgba(0, 0, 0, 0.1)", // Sombra para elementos elevados
  borderColor: "#CCCCCC", // Cor de borda para inputs e botões
  inputBackground: "#FFFFFF", // Fundo de inputs
  hover: "#5a00e6", // Cor para hover de botões e links
};
