import React, { useState } from 'react';
import styled from 'styled-components';
import UserProfile from '../components/organisms/UserProfile';
import FormField from '../components/molecules/FormField';
import InputPhone from '../components/atoms/InputPhone';
import Button from '../components/atoms/Button';
import Tabs from '../components/molecules/Tabs';

const Container = styled.div`
  padding: 40px;
  background-color: ${({ theme }) => theme.body};
  min-height: 100vh;
  color: ${({ theme }) => theme.text};
`;

const Section = styled.div`
  margin-bottom: 50px;
`;

const SectionTitle = styled.h2`
  font-size: 2em;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.primaryColor};
`;

const Description = styled.p`
  font-size: 1.1em;
  margin-bottom: 15px;
`;

const CodeSnippet = styled.pre`
  padding: 20px;
  border-radius: 10px;
  font-size: 1em;
  overflow-x: auto;
  line-height: 1.6;
  font-family: 'Courier New', Courier, monospace;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .keyword {
    color: #d73a49;
    font-weight: bold;
  }
  .string {
    color: #032f62;
  }
  .comment {
    color: #6a737d;
    font-style: italic;
  }
  .function {
    color: #005cc5;
  }
`;

const CodeLine = styled.div`
  line-height: 1.6;
  white-space: pre-wrap;
`;

const Keyword = styled.span`
  color: #d73a49;
  font-weight: bold;
`;

const String = styled.span`
  color: #032f62;
`;

const Separator = styled.hr`
  margin: 40px 0;
  border: none;
  border-top: 2px solid ${({ theme }) => theme.primaryColor};
`;

// Funções de validação de campos
const validateEmail = (value: string): string | null => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!value) return 'Este campo é obrigatório';
  if (!emailRegex.test(value)) return 'Insira um email válido';
  return null;
};

const validatePassword = (value: string): string | null => {
  if (!value) return 'Este campo é obrigatório';
  if (value.length < 6) return 'A senha deve ter pelo menos 6 caracteres';
  return null;
};

// Opções de Tabs
const tabOptions = [
  { name: 'Todos', action: () => console.log('Todos clicado!') },
  { name: 'Para Iniciantes', action: () => console.log('Para Iniciantes clicado!') },
  { name: 'Alto Retorno', action: () => console.log('Alto Retorno clicado!') },
  { name: 'Lucros Estáveis', action: () => console.log('Lucros Estáveis clicado!') },
];

const HomePage: React.FC = () => {
  const [phone, setPhone] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Container>
      {/* Seção do UserProfile */}
      <Section>
        <SectionTitle>User Profile Component</SectionTitle>
        <Description>
          Esse componente exibe o avatar, nome e saldo de um usuário, com a opção de ocultar ou exibir o saldo.
        </Description>
        <UserProfile />
      </Section>


      {/* Seção de Tabs (Underlined) */}
      <Section>
        <SectionTitle>Tabs Component (Underlined Style)</SectionTitle>
        <Description>
          Aqui está um exemplo de como utilizar o componente <strong>Tabs</strong> com opções e estilo underlined:
        </Description>
        <CodeSnippet>
          <CodeLine>
            <Keyword>{'<'}</Keyword>Tabs <Keyword>options=</Keyword><String>{JSON.stringify(tabOptions)}</String> <Keyword>styleType=</Keyword><String>"underlined"</String> <Keyword>{'/>'}</Keyword>
          </CodeLine>
        </CodeSnippet>
        <Tabs options={tabOptions} styleType="underlined" activeTab={activeTab} onTabChange={setActiveTab} />
      </Section>


      {/* Seção de Tabs (Full) */}
      <Section>
        <SectionTitle>Tabs Component (Full Style)</SectionTitle>
        <Description>
          Aqui está um exemplo de como utilizar o componente <strong>Tabs</strong> com estilo "full":
        </Description>
        <CodeSnippet>
          <CodeLine>
            <Keyword>{'<'}</Keyword>Tabs <Keyword>options=</Keyword><String>{JSON.stringify(tabOptions)}</String> <Keyword>styleType=</Keyword><String>"full"</String> <Keyword>{'/>'}</Keyword>
          </CodeLine>
        </CodeSnippet>
        <Tabs options={tabOptions} styleType="full" activeTab={activeTab} onTabChange={setActiveTab} />
      </Section>


      {/* Seção de FormField */}
      <Section>
        <SectionTitle>Form Field Component</SectionTitle>
        <Description>
          Aqui está um exemplo de como utilizar o componente <strong>FormField</strong> com props e validação:
        </Description>
        <CodeSnippet>
          <CodeLine>
            <Keyword>{'<'}</Keyword>FormField <Keyword>placeholder=</Keyword><String>"E-mail"</String> <Keyword>type=</Keyword><String>"text"</String> <Keyword>{'/>'}</Keyword>
          </CodeLine>
          <CodeLine>
            <Keyword>{'<'}</Keyword>FormField <Keyword>placeholder=</Keyword><String>"Password"</String> <Keyword>type=</Keyword><String>"password"</String> <Keyword>{'/>'}</Keyword>
          </CodeLine>
        </CodeSnippet>
        <FormField placeholder="E-mail" type="text" />
        <FormField placeholder="Password" type="password"  />
        <InputPhone
        
          placeholder="Phone number"
          value={phone}
          onChange={setPhone}
        />
      </Section>

      <Separator />

      {/* Seção de Button */}
      <Section>
        <SectionTitle>Button Component</SectionTitle>
        <Description>
          Aqui está um exemplo de como utilizar o componente <strong>Button</strong> com props:
        </Description>
        <CodeSnippet>
          <CodeLine>
         
          </CodeLine>
        </CodeSnippet>
        <Button variant="primary" size="medium" onClick={() => alert('Botão clicado!')}>
          Clique aqui
        </Button>
      </Section>

      <Separator />

      {/* Exemplos de Botões com diferentes Tamanhos */}
      <Section>
        <SectionTitle>Exemplos de Botões com diferentes Tamanhos</SectionTitle>
        <Description>
          Aqui estão exemplos de botões com diferentes tamanhos:
        </Description>
        <Button variant="primary" size="small">Pequeno</Button>
        <br />
        <br />
        <Button variant="primary" size="large">Grande</Button>
      </Section>

      <Separator />

      {/* Exemplos de Variações do Botão */}
      <Section>
        <SectionTitle>Exemplos de Variações do Botão</SectionTitle>
        <Description>
          Aqui estão exemplos de botões com diferentes variações:
        </Description>
        <Button variant="secondary" size="medium">Secundário</Button>
        <Button variant="outline" size="medium">Contorno</Button>
      </Section>

      <Separator />

      {/* Exemplo de Botão com Ação */}
      <Section>
        <SectionTitle>Exemplo de Botão com Ação</SectionTitle>
        <Description>
          Aqui está um exemplo de um botão que dispara um alerta ao ser clicado:
        </Description>
        <Button variant="primary" size="medium" onClick={() => alert('Botão clicado!')}>
          Clique para Alertar
        </Button>
      </Section>
    </Container>
  );
};

export default HomePage;
