import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridValueFormatter,
} from "@mui/x-data-grid";
import api from "../../services/api";
import Swal from "sweetalert2";
import { UserContext } from "../../context/UserContext";
import { formatUSD } from "../../utils/formatUSD";

const TableSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: linear-gradient(
    258.96deg,
    rgba(171, 43, 254, 0.49) 0%,
    rgba(128, 11, 203, 0.22) 31.11%,
    rgba(128, 11, 203, 0.2) 74.42%
  );
  padding: 1rem;
  border-radius: 8px;
  margin-top: 2rem;
  color: white;
`;

const translateStatus = {
  APPROVED: "Aprovado",
  PENDING: "Pendente",
  REJECTED: "Rejeitado",
  IN_PROCESS: "Em Processamento",
  EXPIRED: "Expirado",
  REFUNDED: "Reembolsado",
  CHARGEBACK: "Chargeback",
};

type Status = keyof typeof translateStatus;

const WithdrawalTable: React.FC = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);
  const { formattedBalance } = useContext(UserContext);

  useEffect(() => {
    fetchRequests(paginationModel.page, paginationModel.pageSize);
  }, [paginationModel, formattedBalance]);

  const fetchRequests = async (page: number, pageSize: number) => {
    try {
      setLoading(true);
      const skip = page * pageSize;
      const take = pageSize;

      const response = await api.get(
        `/user/withdraw/requests?skip=${skip}&take=${take}`
      );
      setRequests(response.data.data);
      setRowCount(response.data.count); // Atualiza o número total de registros
    } catch (error) {
      Swal.fire("Erro", "Data could not be loaded.", "error");
    } finally {
      setLoading(false);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "amount",
      headerName: "Amount ($)",
      flex: 1,
      valueFormatter: (params) => `${formatUSD(params)}`,
    },
    { field: "pixKey", headerName: "Wallet ID", flex: 2 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      valueFormatter: (params) => {
        const status = params as Status;
        return translateStatus[status] || params;
      },
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      valueFormatter: (params) => {
        const date = new Date(params);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Meses começam do zero (0 = Janeiro)
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
      },
    },
  ];

  return (
    <TableSection>
      <h4>Withdrawal Requests</h4>
      <DataGrid
        rows={requests}
        columns={columns}
        paginationMode="server"
        rowCount={rowCount}
        loading={loading}
        paginationModel={paginationModel}
        onPaginationModelChange={(model) => setPaginationModel(model)}
        pageSizeOptions={[10, 25, 50]}
        getRowId={(row) => row.id}
        disableColumnMenu
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableColumnSorting
        disableMultipleRowSelection
        sx={{
          color: "#fff",
          "--DataGrid-containerBackground": "transparent",
          ".MuiTablePagination-root": {
            color: "#fff", // Define a cor do texto para branco
          },
          ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
            {
              color: "#fff", // Aplica a cor do texto nas labels de paginação
            },
          ".MuiTablePagination-select": {
            color: "#fff", // Cor do texto no dropdown de seleção
            backgroundColor: "transparent", // Remove fundo cinza padrão
          },
          ".MuiSvgIcon-root": {
            color: "#fff", // Cor dos ícones (como a seta do dropdown)
          },
        }}
      />
    </TableSection>
  );
};
export default WithdrawalTable;
