// src/pages/HomePage.tsx
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Tabs from "../components/molecules/Tabs";
import BottomMenu from "../components/BottomMenu";
import InfluencerCard from "../components/InfluencerCard";
import HeaderLogged from "../components/HeaderLogged";
import mockTraders, { ITrader } from "../mock/mockTraders";
import { UserContext } from "../context/UserContext";
import api from "../services/api";
import Skeleton from "@mui/material/Skeleton";

const PageContainer = styled.div`
  background-color: #000;
  min-height: 100vh;
  padding-bottom: 60px;
  color: white;
`;

const TradersList = styled.div`
  margin-top: 2rem;
  margin-bottom: 10rem;
`;

type TraderType = {
  id: number;
  name: string;
  country: string;
  market: string;
  term: number;
  estimatedProfit: string;
  minimumInvestment: number;
  maximumInvestment: number;
  avatar: string;
  bio: string;
  previousProfit: string;
  operationTerm: string;
  tradeType: string;
  createdAt: string;
  updatedAt: string;
};

const gradient = `linear-gradient(
    258.96deg,
    rgba(171, 43, 254, 0.49) 0%,
    rgba(128, 11, 203, 0.22) 31.11%,
    rgba(128, 11, 203, 0.2) 74.42%
  )`;

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [traders, setTraders] = useState<TraderType[]>([]);
  const { decodedToken, setBalance } = useContext(UserContext);

  const tabOptions = [
    { name: "Traders", action: () => navigate("/home") },
    { name: "My investments", action: () => navigate("/my-deposits") },
  ];

  const handleTabChange = (index: number) => {
    setActiveTab(index);
    tabOptions[index].action();
  };

  useEffect(() => {
    const init = async () => {
      try {
        const res = await api.get("/traders");
        setTraders(res.data);
      } catch { }
    };
    init()
  }, []);

  return (
    <>
      <HeaderLogged nameScreen="Welcome" />
      <div className="container mt-3">
        <div className="row">
          <div className="col-12">
            <Tabs
              options={tabOptions}
              styleType="underlined"
              activeTab={activeTab}
              onTabChange={handleTabChange}
            />
          </div>
        </div>
        <TradersList>
          {traders.length
            ? traders.map((trader) => (
              <InfluencerCard
                key={trader.id}
                id={trader.id}
                term={trader.term}
                name={`${trader.name}`}
                subscribers={0}
                previousProfit={trader.previousProfit}
                operationTerm={trader.operationTerm}
                estimatedProfit={trader.estimatedProfit}
                profileImageSrc={trader.avatar}
                maximumInvestment={trader.maximumInvestment}
                minimumInvestment={trader.minimumInvestment}
                country={trader.country}
                market={trader.market}
                bio={trader.bio}
              />
            ))
            : Array(5)
              .fill("")
              .map(() => (
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height={190}
                  sx={{ background: gradient, my: "10px" }}
                />
              ))}
        </TradersList>
      </div>
      <BottomMenu />
    </>
  );
};

export default HomePage;
