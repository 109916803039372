// src/components/BottomMenu.tsx
import React from "react";
import styled from "styled-components";
import { AiOutlineHome } from "react-icons/ai";
import { BiMoney } from "react-icons/bi";
import { FaUserAlt, FaUsers } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const BottomMenuContainer = styled.nav`
  display: flex;
  justify-content: space-around;
  padding: 1rem 0;
  background-color: #000000;
  position: fixed;
  bottom: 5px;
  width: 315px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50px;
  border: 1px solid #800bcb;
  button {
    background-color: unset;
    color: #fff;
    border: none;
    transition: color 300ms;
    &:hover {
      color: #800bcb;
    }
  }
`;

const BottomMenu: React.FC = () => {
  const navigate = useNavigate();

  return (
    <BottomMenuContainer>
      {/* Home */}
      <button onClick={() => navigate("/home")}>
        <AiOutlineHome size={24} />
      </button>
      {/* Depósito */}
      <button onClick={() => navigate("/deposit")}>
        <BiMoney size={24} />
      </button>
      {/* Perfil */}
      <button onClick={() => navigate("/profile")}>
        <FaUserAlt size={24} />
      </button>

      <button onClick={() => navigate("/dashboard")}>
        <FaUsers size={24} />
      </button>
    </BottomMenuContainer>
  );
};

export default BottomMenu;
