import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: background 0.3s ease-in, color 0.3s ease-in;
  }

  .box {
    background: ${({ theme }) => theme.boxBackground};
    box-shadow: 0px 4px 10px ${({ theme }) => theme.shadow};
    padding: 20px;
    border-radius: 10px;
  }

  h1, h2, h3, p {
    margin: 0;
    padding: 0;
  }
  
  /* Aqui você pode acessar o primary color (roxo) do tema */
  input:focus {
    border-color: ${({ theme }) => theme.primaryColor}; /* Usando o primaryColor */
  }
`;
