import React, { useContext, useState } from "react";
import styled from "styled-components";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import defaultProfileImage from "../../images/profile.png";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { HiDotsVertical } from "react-icons/hi";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";

interface UserProfileProps {
  name?: string;
  balance?: string | number;
  profileImageSrc?: string;
}

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 15px;
  color: ${({ theme }) => theme.text}; /* Usa a cor de texto do tema */
  width: 100%;
  max-width: 350px;
  justify-content: flex-end;
  background-color: ${({ theme }) =>
    theme.background}; /* Cor de fundo do tema */
`;

const Avatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.div`
  font-size: 1em;
  font-weight: bold;
  color: ${({ theme }) => theme.text}; /* Usa a cor de texto do tema */
`;

const BalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

const Balance = styled.div`
  font-size: 1rem;
  margin-right: 10px;
  color: ${({ theme }) => theme.text}; /* Usa a cor de texto do tema */
  align-self: center;
`;

const EyeIcon = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.text}; /* Usa a cor de ícone do tema */
`;

const UserProfile: React.FC<UserProfileProps> = ({
  name,
  balance,
  profileImageSrc = defaultProfileImage,
}) => {
  const [isBalanceVisible, setIsBalanceVisible] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { signOut } = useContext(UserContext);
  const navigate = useNavigate()


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <ProfileWrapper>
      <Avatar src={profileImageSrc} alt="User Avatar" />
      <UserInfo>
        <Name>{name}</Name>
        <BalanceWrapper>
          <Balance>{isBalanceVisible ? balance : "*****"}</Balance>
          <EyeIcon onClick={() => setIsBalanceVisible(!isBalanceVisible)}>
            {isBalanceVisible ? (
              <AiFillEyeInvisible size={24} />
            ) : (
              <AiFillEye size={24} />
            )}
          </EyeIcon>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            sx={{ color: "#fff" }}
          >
            <HiDotsVertical />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            slotProps={{
              paper: {
                style: {
                  maxHeight: 48 * 4.5,
                  width: "20ch",
                  background:
                    "linear-gradient(180deg, #800bcb 0%, #400565 80.46%)",
                  color: "#fff",
                },
              },
            }}
          >
            <MenuItem onClick={() => navigate("/statements")} sx={{borderBottom: "1px solid #fff"}}>Bank Statements</MenuItem>
            <MenuItem onClick={signOut}>Logout</MenuItem>
          </Menu>
        </BalanceWrapper>
      </UserInfo>
    </ProfileWrapper>
  );
};

export default UserProfile;
