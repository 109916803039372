import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Button from "../components/atoms/Button";
import FormField from "../components/molecules/FormField";
import HeaderLogged from "../components/HeaderLogged";
import QRCode from "react-qr-code";
import Swal from "sweetalert2";
import { updatePassword } from "../services/authService";
import api from "../services/api";
import { UserContext } from "../context/UserContext";

const Container = styled.div`
  background-color: #000;
  min-height: 100vh;
  color: white;
`;

const CardContainer = styled.div`
  border: solid 1px #7575758f;
  border-radius: 12px;
  padding: 32px;
  box-shadow: 0px 5px 12px 0px #1c1c1c3b;
  height: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BackIcon = styled(AiOutlineArrowLeft)`
  font-size: 1.5rem;
  cursor: pointer;
  margin-right: 10px;
  color: #b08cff;
`;

const ActionButton = styled(Button)`
  width: 100%;
  margin-top: 1.5rem;
`;

const QRWrapper = styled.div`
  background-color: #111;
  padding: 1.5rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 4px solid #b08cff;
  margin-bottom: 12px;
`;

const QRTitle = styled.h3`
  color: white;
  margin-bottom: 1rem;
  text-align: center;
`;

const CopyButton = styled.button`
  background-color: #b08cff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #a085e5;
  }
`;

const CodeInputContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const CodeInputBox = styled.input`
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 2px solid #b08cff;
  border-radius: 5px;
  color: white;
  font-size: 1.5rem;
  text-align: center;

  &:focus {
    outline: none;
    border-color: #ffffff;
  }
`;

const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const { decodedToken, changeToken } = useContext(UserContext);
  const [code, setCode] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [authGenerated, setAuthGenerated] = useState<{
    qrcode: string;
    otpURL: string;
    secretKey: null | string;
  }>({
    qrcode: "",
    otpURL: "",
    secretKey: null,
  });

  // useEffect(() => {
  //   const init = async () => {
  //     try {
  //       const { data } = await api.get("/auth/mfa/generate");
  //       setAuthGenerated(data);
  //     } catch (error: any) {
  //       Swal.fire("Erro", error.message, "error");
  //     }
  //   };
  //   init();
  // }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCodeChange = async (value: string, index: number) => {
    const newCode = [...code];
    newCode[index] = value.slice(-1);
    setCode(newCode);
    console.log(code, "cos");
    if (value && index < 5) {
      document.getElementById(`code-input-${index + 1}`)?.focus();
    }

    if (index >= 5) {
      submitCode(value);
    }
  };

  const submitCode = async (lastNumber?: string) => {
    try {
      const finalCode = lastNumber
        ? parseInt(code.join("") + lastNumber)
        : parseInt(code.join(""));

      await api.post("/auth/mfa/enable", {
        code: finalCode,
      });
      Swal.fire("Success", "Successfully linked!", "success");
    } catch (error: any) {
      Swal.fire("Erro", error.fields[0].message, "error");
    }
  };

  const handleSubmit = async () => {
    const { currentPassword, newPassword, confirmPassword } = formData;

    if (!currentPassword || !newPassword || !confirmPassword) {
      Swal.fire("Erro", "Please fill in all the fields.", "error");
      return;
    }

    if (newPassword !== confirmPassword) {
      Swal.fire("Erro", "New password and confirmation do not match.", "error");
      return;
    }

    if (newPassword.length < 8) {
      Swal.fire(
        "Erro",
        "The new password must be at least 8 characters long.",
        "error"
      );
      return;
    }

    try {
      setLoading(true);
      const email = decodedToken?.email;
      const res = await updatePassword(email, currentPassword, newPassword);
      changeToken(res.token);
      Swal.fire("Success", "Password changed successfully.", "success");
    } catch (error: any) {
      Swal.fire("Erro", error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(authGenerated.otpURL);
    Swal.fire(
      "Copied!",
      "QR Code copied to clipboard.",
      "success"
    );
  };

  return (
    <>
      <Container className="container-fluid pb-4">
        <HeaderLogged nameScreen={"Profile"} />
        <div className="container mt-4">
          <div className="d-flex align-items-center">
            <BackIcon onClick={() => navigate("/home")} />
            <h3>Back</h3>
          </div>
          <div className="row mt-3">
            <div className="col-md-6 col-sm-12 mb-3">
              <CardContainer>
                <p>Change Password</p>
                <FormField
                  name="currentPassword"
                  placeholder="Current Password"
                  type="password"
                  value={formData.currentPassword}
                  onChange={handleChange}
                />
                <FormField
                  name="newPassword"
                  placeholder="New Password"
                  type="password"
                  value={formData.newPassword}
                  onChange={handleChange}
                />
                <FormField
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  type="password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
                <ActionButton
                  isLoading={loading}
                  variant="primary"
                  onClick={handleSubmit}
                >
                  Confirm
                </ActionButton>
              </CardContainer>
            </div>
            <div className="col-md-6 col-sm-12 mb-3">
              <CardContainer>
                <QRWrapper>
                  <QRTitle>Scan the QR CODE</QRTitle>
                  <QRCode
                    value={authGenerated.otpURL}
                    size={150}
                    fgColor="#ffffff"
                    bgColor="transparent"
                  />
                  <CopyButton onClick={handleCopy}>COPY KEY</CopyButton>
                </QRWrapper>
                <QRTitle>Enter the 2FA code</QRTitle>
                <CodeInputContainer>
                  {Array(6)
                    .fill("")
                    .map((digit, index) => (
                      <CodeInputBox
                        key={index}
                        id={`code-input-${index}`}
                        type="text"
                        maxLength={1}
                        value={code[index]}
                        onChange={(e) =>
                          handleCodeChange(e.target.value, index)
                        }
                      />
                    ))}
                </CodeInputContainer>
                <ActionButton variant="primary">SEND</ActionButton>
              </CardContainer>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ProfilePage;
