// src/pages/DepositPage.tsx
import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Form, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft, AiOutlineDollar } from "react-icons/ai";
import Tabs from "../components/molecules/Tabs";
import Button from "../components/atoms/Button";
import FormField from "../components/molecules/FormField";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderLogged from "../components/HeaderLogged";
import { MdOutlinePix } from "react-icons/md";
import { FaBity, FaCopy, FaUniversity } from "react-icons/fa";
import {
  NumberFormatValues,
  NumericFormat,
  PatternFormat,
} from "react-number-format";
import IsValidCpf from "../utils/validateCPF";
import Swal from "sweetalert2";
import api from "../services/api";
import QRCode from "react-qr-code";
import { UserContext } from "../context/UserContext";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
// import { DOLLAR_PRICE } from "../constants/dollar";
import { CircularProgress } from "@mui/material";
import BottomMenu from "../components/BottomMenu";
import axios from "axios";
import { DOLLAR_PRICE } from "../constants/dollar";
import { getRate } from "../utils/getRate";

const Container = styled.div`
  background-color: #000;
  min-height: 100vh;
  color: white;
`;

const BackIcon = styled(AiOutlineArrowLeft)`
  font-size: 1.5rem;
  cursor: pointer;
  margin-right: 10px;
  color: #b08cff;
`;

const TabSection = styled.div`
  margin-top: 1rem;
`;

const PixButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: linear-gradient(180deg, rgba(34, 4, 54, 0.69) 0%, #220436 100%);
  backdrop-filter: blur(4px);
  box-shadow: 0px 0px 7px 1px #ffffff40;
  color: #fff;
  border-radius: 8px;
  max-width: 400px;
  text-align: center;
  margin-top: 0.5rem;
`;

const TransactionSection = styled.div`
  background: linear-gradient(
    258.96deg,
    rgba(171, 43, 254, 0.49) 0%,
    rgba(128, 11, 203, 0.22) 31.11%,
    rgba(128, 11, 203, 0.2) 74.42%
  );
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
`;

const ExceptionSection = styled.div`
  span:nth-child(1) {
    color: #00aeff;
  }
  span:nth-child(2) {
    color: #fff;
  }
  span:nth-child(3) {
    color: #ffffff85;
  }
`;

const ActionButton = styled(Button)`
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 80px;
`;

const Stepper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
  color: #ffffff;
  max-width: 400px;
  margin: auto;
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &:not(:last-child)::after {
    content: "";
    position: absolute;
    left: 24px;
    top: 75px;
    width: 2px;
    height: calc(100% - 180px);
    background-color: #d9d9d9;
  }

  div:first-child {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(180deg, #190228 0%, #4d1272 100%);
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    margin-right: 15px;
    padding: 20px;
    z-index: 1;
  }

  div:last-child {
    h5 {
      color: #800bcb;
    }
  }
`;

const ToReceive = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  span {
    color: #00ff95;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: #ffffff85;
  margin: 14px 0px;
  border-radius: 5px;
`;

const QrCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const QrCodeAdjusts = styled.div`
  padding: 10px;
  border-radius: 6px;
  background-color: #fff;
`;

const CopyCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    background-color: ${({ theme }) => theme.primaryColor};
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-left: 10px;
    &:hover {
      background-color: ${({ theme }) => theme.hover};
    }
  }
`;

const ApprovedContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
      color: greenyellow;
      font-size: 100px;
    }
`;

const DepositPage: React.FC = () => {
  const [DOLLAR_PRICE, setDollarPrice] = useState(6.35);

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    price: "",
    cpf: "",
  });
  const [qrCode, setQrCode] = useState("");
  const { decodedToken, setBalance } = useContext(UserContext);

  const handleChange = (val: string, name: keyof typeof formData) => {
    setFormData({ ...formData, [name]: val });
  };
  // useEffect(() => {
  //   const fetchDollarPrice = async () => {
  //     const price = await getRate();
  //     setDollarPrice(price);
  //   }

  //   fetchDollarPrice();
  // }, []);

  const reaisConverter = useMemo(() => {
    const centavosPrice = parseFloat(formData.price)
    if (!isNaN(centavosPrice)) {
      return parseFloat((centavosPrice * DOLLAR_PRICE).toFixed(2))
    }
    return 0
  }, [formData.price])

  const tabOptions = [
    { name: "Withdrawal", action: () => navigate("/withdrawal") },
    { name: "Deposits", action: () => navigate("/deposit") },
  ];
  const handleTabChange = (index: number) => {
    setActiveTab(index);
    tabOptions[index].action();
  };

  const handleSubmit = async () => {
    if (!IsValidCpf(formData.cpf)) {
      Swal.fire("Erro", "Esse cpf é invalido", "error");
      return;
    }

    if (reaisConverter === 0) {
      Swal.fire("Erro", "Adicione um valor válido", "error");
      return;
    }

    try {
      setLoading(true);
      const res = await api.post("/payment/generate/bloopay", {
        name: decodedToken.name,
        cpf: formData.cpf,
        // price: (reaisConverter + reaisConverter * 0.05) * 100,
           price: reaisConverter * 100,
        user: decodedToken.username,
      });
      setQrCode(res.data.qrcode);
      startChecking(res.data.transactionId);
    } catch (e) {
      Swal.fire("Erro", "Ocorreu um erro ao tentar gerar o pix", "error");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const startChecking = (transactionId: string) => {
    const intervalId = setInterval(async () => {
      await fetchData();
    }, 10000);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, 60000 * 5);

    const fetchData = async () => {
      try {
        const response = await api.get(
          `/payment/transactionStatus/bloopay/${transactionId}`
        );

        if (response.data.status === "approved") {
          const res = await api.get("/user/info");
          setBalance(res.data.balance.amount);
          setQrCode("approved");
          clearInterval(intervalId);
        }
      } catch (err) { }
    };

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  };

  return (
    <>
      <Container className="container-fluid pb-4">
        <HeaderLogged />
        <div className="container mt-4">
          <div className="d-flex align-items-center">
            <BackIcon onClick={() => navigate("/home")} />
            <h3>Back</h3>
          </div>
          <div className="row">
            <div className="col-12">
              <TabSection>
                <Tabs
                  options={tabOptions}
                  styleType="underlined"
                  activeTab={activeTab}
                  onTabChange={handleTabChange}
                />
              </TabSection>
            </div>
          </div>
          <div className="row mt-3 justify-content-center">
            <div className="col-4">
              <PixButton variant="primary">
                <MdOutlinePix />
                PIX
              </PixButton>
            </div>
            <div className="col-4">
              <PixButton variant="primary">
              <AiOutlineDollar />
                USDT
              </PixButton>
            </div>
            <div className="col-4">
              <PixButton variant="primary">
              <FaBity />
                BFXC
              </PixButton>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <TransactionSection>
                <PatternFormat
                  customInput={FormField}
                  placeholder="CPF"
                  onValueChange={(e) => {
                    handleChange(e.formattedValue, "cpf");
                  }}
                  format="###.###.###-##"
                  mask="_"
                  valueIsNumericString
                  style={{ border: "2px solid #fff" }}
                />
                <NumericFormat
                  value={formData.price}
                  prefix={"$"}
                  allowNegative={false}
                  customInput={FormField}
                  thousandSeparator="."
                  decimalSeparator=","
                  onValueChange={(e) => {
                    handleChange(e.value, "price");
                  }}
                  placeholder="Pay"
                  style={{ border: "2px solid #fff" }}
                />

                <ExceptionSection>
                  <span>*</span>
                  <span> Limit of:</span>
                  <span> $1 - $50.000</span>
                </ExceptionSection>
                <div></div>
                <ToReceive>
                  <FaUniversity /> BRL Conversion:
                   <span>R$ {reaisConverter.toLocaleString()}</span>
                </ToReceive>
                <Line></Line>
                <ExceptionSection>
                  {/* <span>*</span>
                  <span> Patrimonial Value:</span>
                  <span> (1 USDT = {DOLLAR_PRICE} R$)</span> */}
                </ExceptionSection>
              </TransactionSection>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              {!qrCode ? (
                <Stepper>
                  <Step>
                    <div>1</div>
                    <div>
                      <h5>Access your bank app and choose Pix:</h5>
                      <p>
                        Open the app, go to the Pix area, and select the payment or transfer option.
                      </p>
                    </div>
                  </Step>
                  <Step>
                    <div>2</div>
                    <div>
                      <h5>Enter the details and amount:</h5>
                      <p>
                        Choose the type of Pix key (CPF, email, phone, or random key), enter the key and the transaction amount.
                      </p>
                    </div>
                  </Step>
                  <Step>
                    <div>3</div>
                    <div>
                      <h5>Confirm the transaction:</h5>
                      <p>
                        Verify the details, confirm the transaction, and you will be redirected to our success page! Shortly after, the amount will be credited to your wallet.
                      </p>
                    </div>
                  </Step>
                </Stepper>
              ) : qrCode !== "approved" ? (
                <QrCodeContainer>
                  <QrCodeAdjusts>
                    <QRCode value={qrCode} strokeWidth={10} />
                  </QrCodeAdjusts>
                  <CopyCode>
                    <FormField type="text" readOnly value={qrCode} />
                    <button
                      onClick={() => navigator.clipboard.writeText(qrCode)}
                    >
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        Copy <FaCopy />
                      </p>
                    </button>
                  </CopyCode>
                  <CircularProgress />
                </QrCodeContainer>
              ) : (
                <ApprovedContainer>
                  <IoMdCheckmarkCircleOutline />
                  <h1>Success!</h1>
                  <p>Thank you for trusting our service!</p>
                </ApprovedContainer>
              )}
            </div>

          </div>
          <div className="row mt-4">
            <div className="col-12">
              <ActionButton
                variant="primary"
                isLoading={loading}
                onClick={handleSubmit}
              >
                Confirm
              </ActionButton>
            </div>
          </div>
        </div>
        <BottomMenu />
      </Container>
    </>
  );
};

export default DepositPage;