import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Carousel, Button, ButtonProps } from 'react-bootstrap';
import { FiTrendingUp, FiDollarSign, FiShield } from 'react-icons/fi';
import backgroundBannerCaroussel1 from '../images/6256878.jpg';
import backgroundBannerCaroussel2 from '../images/modern-building-pink-background.jpg';
import logo from '../images/logo.png';
import Modal from '../components/Modal/Modal';
import BullChegando from "../images/bull-chegando.png"
import { useNavigate } from 'react-router-dom';
// Animações
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

// Wrapper de carregamento com visibilidade controlada
const LoadingWrapper = styled.div<{ isLoading: boolean }>`
  display: ${({ isLoading }) => (isLoading ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #121212;
  color: #fff;
  font-size: 1.5rem;
  z-index: 10;
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #820AD1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
`;

const GradientTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
  background: linear-gradient(45deg, #820AD1, #ff6f61);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 2;
  animation: ${fadeIn} 1.5s ease-out;
  position: relative;
`;

const GradientSectionTitle = styled.h2`
  font-size: 1.75rem;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(45deg, #820AD1, #ff6f61);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 2rem;
  animation: ${fadeIn} 1.5s ease-out;
`;

const SectionText = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.text};
  line-height: 1.6;
  text-align: justify;
`;

const CTAButton = styled(Button).attrs<ButtonProps>({ as: 'a' })`
  background: linear-gradient(90deg, #820AD1, #ff6f61);
  font-size: 1.5rem;
  padding: 1rem 3rem;
  margin-top: 2rem;
  color: #fff;
  border-radius: 12px;
  transition: all 0.4s ease;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);

  &:hover {
    background: linear-gradient(90deg, #660a99, #ff6f61);
    transform: scale(1.08);
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.4);
  }
`;

const Footer = styled.footer`
  background-color: #121212;
  color: #fff;
  padding: 2rem 0;
  text-align: center;
  animation: ${fadeIn} 1.5s ease-out;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
`;

const CarouselImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
`;

const CarouselImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CarouselCaption = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 2;
`;

const SalesPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setModalOpen] = useState(true);
  const navigate = useNavigate();
 
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  const handleAction = () => {
    navigate('/login')
    setModalOpen(false);
  };

return (      <Modal
  isOpen={isModalOpen}
  onClose={() => setModalOpen(false)}
  title="Aviso Importante"
  imageUrl={BullChegando}
  buttonText="Entendi"
  onAction={handleAction}
/>)
  return (
    <>
      <img style={{ position: 'absolute', width: '150px', zIndex: '9999', left: '5%' }} src={logo} alt="" />
      <LoadingWrapper isLoading={loading}>
        <Spinner />
      </LoadingWrapper>

      {!loading && (
        <>
          <Carousel fade>
            <Carousel.Item>
              <CarouselImageWrapper>
                <CarouselImage src={backgroundBannerCaroussel1} alt="First slide" />
                <Overlay />
                <CarouselCaption>
                  <GradientTitle>BullFX</GradientTitle>
                  <p>Tecnologia e expertise ao seu dispor</p>
                </CarouselCaption>
              </CarouselImageWrapper>
            </Carousel.Item>
            <Carousel.Item>
              <CarouselImageWrapper>
                <CarouselImage src={backgroundBannerCaroussel2} alt="Second slide" />
                <Overlay />
                <CarouselCaption>
                  <GradientTitle>Conecte-se com Traders de Elite</GradientTitle>
                  <p>Estratégias avançadas e suporte profissional</p>
                </CarouselCaption>
              </CarouselImageWrapper>
            </Carousel.Item>
          </Carousel>

          {/* Slide 2: Quem Somos */}
          <Row className="my-5 p-5 p-4">
            <Col>
              <GradientSectionTitle>Quem Somos</GradientSectionTitle>
              <SectionText>
                A BullFX é uma sociedade anônima inscrita sob o número HE 254132 Αριθμός Εγγρφής Φ.Π.O em Limassol, Chipre, fundada pela união de traders renomados de diversas partes do mundo. Nossa missão é redefinir a experiência financeira dos nossos clientes, combinando conhecimento com inovação para resultados reais. Na BullFX, nosso sucesso só acontece quando nossos usuários prosperam.
              </SectionText>
              <p style={{ textAlign: 'center', color: '#ccc' }}>
                30 Hudson Yards, Nova York, NY, Estados Unidos | ICD Brookfield Place, Dubai, Emirados Árabes Unidos | FXTM Tower, Limassol, Chipre
              </p>
            </Col>
          </Row>

          {/* Slide 3: Missão, Visão e Valores */}
          <Row className="my-5 p-5">
            <Col>
              <GradientSectionTitle>Missão, Visão e Valores</GradientSectionTitle>
              <SectionText>
                <strong>Missão:</strong> Oferecer uma experiência financeira diferenciada e acessível, projetada para maximizar retornos e colocar o cliente no centro das nossas operações.
              </SectionText>
              <SectionText>
                <strong>Visão:</strong> Ser uma referência global em soluções financeiras inteligentes, estabelecendo novos padrões de confiança e impulsionando o crescimento sustentável no mercado financeiro.
              </SectionText>
              <SectionText>
                <strong>Valores:</strong>
                <ul>
                  <li>Transparência: Compromisso com práticas éticas e comunicação clara.</li>
                  <li>Inovação: Evolução contínua para resultados aprimorados.</li>
                  <li>Excelência: Foco em ferramentas de alta performance.</li>
                  <li>Compromisso com o Cliente: Prioridade ao sucesso do cliente.</li>
                  <li>Trabalho em Equipe: Colaboração global para impacto positivo.</li>
                </ul>
              </SectionText>
            </Col>
          </Row>

          {/* Slide 4: Diferenciais e Ferramentas */}
          <Row className="my-5 p-5">
            <Col md={4} className="text-center">
              <FiTrendingUp size={50} color="#ff6f61" />
              <GradientSectionTitle>Diferenciais</GradientSectionTitle>
              <SectionText>
                Inteligência Artificial e Ferramentas de Última Geração – Análises de mercado precisas e ágeis, potencializando suas oportunidades com eficiência.
              </SectionText>
            </Col>
            <Col md={4} className="text-center">
              <FiDollarSign size={50} color="#ff6f61" />
              <GradientSectionTitle>Software Proprietário</GradientSectionTitle>
              <SectionText>
                Nosso software de inteligência artificial monitora o mercado em tempo real, detectando oportunidades de investimento valiosas e oferecendo uma experiência segura e estratégica.
              </SectionText>
            </Col>
            <Col md={4} className="text-center">
              <FiShield size={50} color="#ff6f61" />
              <GradientSectionTitle>Ferramentas de Alavancagem</GradientSectionTitle>
              <SectionText>
                Desenhadas para potencializar seu capital e maximizar ganhos, nossas ferramentas de alavancagem equilibram precisão e segurança, priorizando uma gestão de risco eficaz para garantir resultados consistentes.
              </SectionText>
            </Col>
          </Row>

          {/* Slide 5: Por que Escolher a BullFX? */}
          <Row className="my-5 p-5 text-center">
            <Col>
              <GradientSectionTitle>Por que Escolher a BullFX?</GradientSectionTitle>
              <SectionText>
                Na BullFX, nosso compromisso é com a sua transparência, segurança e crescimento financeiro. Criamos uma plataforma intuitiva e poderosa que simplifica o processo de investimento, ajudando você a alcançar seus objetivos financeiros de forma estratégica e segura.
              </SectionText>
            </Col>
          </Row>

          {/* Slide 9: Regras de Negócio - Informações Importantes da BullFX */}
          <Row className="my-5 p-5">
            <Col>
              <GradientSectionTitle>Regras de Negócio</GradientSectionTitle>
              <SectionText>
                <strong>Taxa de Performance:</strong> 80/20 – 80% do lucro para o usuário e 20% para o trader.
              </SectionText>
              <SectionText>
                <strong>Taxa de Trading:</strong> 0,10% por ordem de abertura/encerramento de operação.
              </SectionText>
            </Col>
          </Row>

          {/* Localizações das Sedes */}
          <Row className="my-5 p-5">
            <Col>
              <GradientSectionTitle>Nossas Localizações</GradientSectionTitle>
              <SectionText>
                <strong>Sede Principal:</strong> FXTM Tower, 35 Lamprou Konstantara, Kato Polemidia, Limassol, Chipre
              </SectionText>
              <SectionText>
                <strong>Sede Dubai:</strong> ICD Brookfield Place, Al Mustaqbal Street, Dubai International Financial Centre (DIFC), Dubai, Emirados Árabes Unidos
              </SectionText>
              <SectionText>
                <strong>Sede Nova York:</strong> 30 Hudson Yards, Nova York, NY, Estados Unidos
              </SectionText>
            </Col>
          </Row>

          {/* Footer */}
          <Footer>
            <Row>
              <Col>
                <p>&copy; 2024 BullFX. Todos os direitos reservados.</p>
                <p>Endereços:</p>
                <p>30 Hudson Yards, Nova York, NY, Estados Unidos</p>
                <p>ICD Brookfield Place, Dubai, Emirados Árabes Unidos</p>
                <p>FXTM Tower, Limassol, Chipre</p>
              </Col>
            </Row>
          </Footer>
        </>
      )
      }
    </>
  );
};

export default SalesPage;

