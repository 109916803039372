// src/pages/LoginPage.tsx

import React, { useContext, useState } from "react";
import styled from "styled-components";
import Swal from "sweetalert2";
import Button from "../components/atoms/Button";
import FormField from "../components/molecules/FormField";
import beeImg from "../images/logo.png";
import { login } from "../services/authService";
import "bootstrap/dist/css/bootstrap.min.css";
import { UserContext } from "../context/UserContext";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #000;
  color: #fff;
  padding: 2rem;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #111;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  width: 100%;

  img {
    align-self: center;
    max-width: 100%;
    width: 200px;
    margin-bottom: 10px;
  }
`;

const Heading = styled.h2`
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 1rem;
  text-align: center;
  span {
    color: #b08cff;
  }
`;

const LoginPage: React.FC = () => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    try {
      setLoading(true)
      const response = await login(credentials.email, credentials.password);

      if (response) {
        // Salvar tokens no localStorage
        Swal.fire({
          icon: "success",
          title: "Login Successful!",
          text: "You have logged in successfully.",
        }).then(() => {
          window.location.href = "/welcome"; // Redireciona para a página de boas-vindas
        });
      }
    } catch (error: any) {
      setLoading(false)
      Swal.fire({
        icon: "error",
        title: "Login Failed",
        text: error.response?.data?.message || "Incorrect username or password. Please try again.",
      });
      console.error("Login error:", error);
    }
  };
  

  return (
    <StyledContainer>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <FormWrapper>
              <img src={beeImg} alt="Logo" />
              <Heading>
                <span>BullFx</span>, Where Your Finances Thrive!
              </Heading>
              <form onSubmit={handleSubmit} className="needs-validation" noValidate>
                <div className="mb-3">
                  <FormField
                    name="email"
                    placeholder="email@exemplo.com"
                    type="text"
                    value={credentials.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <FormField
                    name="password"
                    placeholder="Password"
                    type="password"
                    value={credentials.password}
                    onChange={handleChange}
                  />
                </div>
                <Button isLoading={loading} variant="gradient" size="large" type="submit">
                  Login
                </Button>
              </form>
              <p className="text-center mt-3">
                Don't have an account?{" "}
                <a href="/register?referrer=bulldiamante" style={{ color: "#b08cff" }}>
                  Sign Up
                </a>
              </p>
            </FormWrapper>
          </div>
        </div>
      </div>
    </StyledContainer>
  );
};

export default LoginPage;
