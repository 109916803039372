import React, { useState } from "react";
import styled from "styled-components";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 10px;
`;

const StyledInput = styled.input<{ isPassword?: boolean; hasError: boolean }>`
  width: 100%;
  padding: 10px;
  padding-right: ${({ isPassword }) => (isPassword ? "40px" : "10px")};
  margin: 10px 0;
  border-radius: 5px;
  border: 2px solid ${({ hasError, theme }) => (hasError ? "#ff4d4f" : theme.primaryColor)};
  background-color: transparent;
  color: ${({ theme }) => theme.text};
  font-size: 16px;
  transition: border-color 0.3s, color 0.3s;
  appearance: none;

  ::placeholder {
    color: #a0a0a0;
  }

  &:focus {
    border-color: ${({ theme, hasError }) => (hasError ? "#ff4d4f" : theme.primaryColor)};
    outline: none;
  }
`;

const ErrorText = styled.p`
  color: #ff4d4f;
  font-size: 12px;
  margin: 5px 0 0;
  position: absolute;
  left: 10px;
`;

const TogglePasswordIcon = styled.div<{ hasError: boolean }>`
  position: absolute;
  top: ${({ hasError }) => (hasError ? "calc(50% + 2px)" : "50%")};
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: ${({ theme }) => theme.text};
  transition: ease 100ms;
`;

interface InputProps extends React.ComponentPropsWithoutRef<"input"> {
  type: string;
  placeholder: string;
  hasError: boolean;
  errorMessage?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ type, placeholder, hasError, errorMessage, ...rest }, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
      setShowPassword((prev) => !prev);
    };

    return (
      <InputWrapper>
        <StyledInput
          ref={ref}
          type={type === "password" && showPassword ? "text" : type}
          placeholder={placeholder}
          isPassword={type === "password"}
          hasError={hasError}
          {...rest}
        />
        {type === "password" && (
          <TogglePasswordIcon hasError={hasError} onClick={handleTogglePassword}>
            {showPassword ? <AiFillEyeInvisible size={24} /> : <AiFillEye size={24} />}
          </TogglePasswordIcon>
        )}
        {hasError && <ErrorText>{errorMessage}</ErrorText>}
      </InputWrapper>
    );
  }
);

export default Input;
