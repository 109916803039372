import React from 'react';
import styled from 'styled-components';

interface TabOption {
  name: string;
  action: () => void;
}

interface TabsProps {
  options: TabOption[];
  styleType: 'underlined' | 'full';
  activeTab: number;
  onTabChange: (index: number) => void;
}

const TabsContainer = styled.div<{ styleType: 'underlined' | 'full' }>`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${({ styleType }) => (styleType === 'underlined' ? '10px' : '0')};
`;

const Tab = styled.button<{ isActive: boolean; styleType: 'underlined' | 'full' }>`
  flex: 1;
  background-color: ${({ isActive, styleType }) =>
    styleType === 'full'
      ? isActive
        ? '#6a00ff'
        : '#3b005a'
      : 'transparent'};
  color: ${({ isActive }) => (isActive ? 'white' : '#b3b3b3')};
  border: none;
  padding: 18px 5px;
  font-size: 1rem;
  margin-right: 10px;
  cursor: pointer;
  border-radius: ${({ styleType }) => (styleType === 'full' ? '8px' : '6px')};
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  
  /* Aplica box-shadow apenas se o estilo for "full" */
  box-shadow: ${({ isActive, styleType }) =>
    isActive && styleType === 'full' ? '0 4px 15px rgba(106, 0, 255, 0.7)' : 'none'};
  
  transform: ${({ isActive }) => (isActive ? 'scale(1.05)' : 'scale(1)')};

  &:hover {
    transform: scale(1.05); /* Aumenta levemente ao passar o mouse */
    background-color: ${({ isActive, styleType }) =>
      styleType === 'full' && !isActive ? '#50007f' : 'transparent'};
    color: white;
  }

  ${({ styleType, isActive }) =>
    styleType === 'underlined' &&
    `
    border-bottom: 3px solid ${isActive ? '#6a00ff' : 'transparent'};
    &:hover {
      border-bottom: 2px solid #6a00ff;
    }
  `}
`;

const Tabs: React.FC<TabsProps> = ({ options, styleType, activeTab, onTabChange }) => {
  return (
    <TabsContainer styleType={styleType}>
      {options.map((option, index) => (
        <Tab
          key={index}
          isActive={activeTab === index}
          styleType={styleType}
          onClick={() => onTabChange(index)}
        >
          {option.name}
        </Tab>
      ))}
    </TabsContainer>
  );
};

export default Tabs;
