import React from "react";
import styled from "styled-components";
import { formatUSD } from "../utils/formatUSD";

interface InvestmentCardProps {
  name: string;
  bio?: string;
  profileImageSrc?: string;
  previousProfit: string | number;
  estimatedProfit: string | number;
  operationTerm: string | number;
  minimumInvestment: number;
  maximumInvestment: number;
  amount: number;
  startDate: string;
  endDate: string;
  profit: number;
  traderId: number;
  dailyProfit: number;
  accumulatedProfit: number;
  onClick: () => void;
}

const Card = styled.div`
  background: linear-gradient(
    258.96deg,
    rgba(171, 43, 254, 0.49) 0%,
    rgba(128, 11, 203, 0.22) 31.11%,
    rgba(128, 11, 203, 0.2) 74.42%
  );
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  color: white;
  margin: 10px 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ProfileInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
`;

const Avatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

const NameAndBio = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Name = styled.div`
  font-size: 1rem;
  font-weight: bold;
`;

const Bio = styled.div`
  font-size: 0.7rem;
  color: #cccccc;
`;

const Stats = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
`;

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const StatLabel = styled.div`
  font-size: 0.7rem;
  color: #cccccc;
  width: 100%;
  text-align: center;
`;

const StatValue = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
`;

const ProgressBarContainer = styled.div`
  margin-top: 15px;
  width: 100%;
  background-color: #444;
  border-radius: 10px;
  overflow: hidden;
`;

const ProgressBar = styled.div<{ progress: number }>`
  width: ${(props) => props.progress}%;
  height: 10px;
  background-color: #00ff95;
  transition: width 0.3s ease;
`;

const HistoryButton = styled.button`
  background: linear-gradient(90deg, #00ff95, #12ca7d);
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.9;
  }
`;

const InvestmentCard: React.FC<InvestmentCardProps> = ({
  name,
  bio,
  profileImageSrc,
  previousProfit,
  estimatedProfit,
  operationTerm,
  minimumInvestment,
  maximumInvestment,
  amount,
  startDate,
  endDate,
  profit,
  traderId,
  dailyProfit,
  accumulatedProfit,
  onClick,
}) => {
  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();
  const now = new Date().getTime();

  const totalDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
  const elapsedDays = Math.max(
    Math.ceil((now - start) / (1000 * 60 * 60 * 24)),
    1
  );

  const progress = Math.min((elapsedDays / totalDays) * 100, 100);


  const accumulatedProfitPercent = formatUSD(accumulatedProfit);

  return (
    <Card onClick={onClick}>
      <Header>
        <ProfileInfo>
          <Avatar src={profileImageSrc} alt={`${name}'s avatar`} />
          <NameAndBio>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Name>{name}</Name>
              <HistoryButton
                style={{ marginLeft: "auto" }}
                onClick={(e) => {
                  e.stopPropagation();
                  onClick();
                }}
              >
                History
              </HistoryButton>
            </div>
            {bio && (
              <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                <Bio>{bio}</Bio>
              </div>
            )}
          </NameAndBio>
        </ProfileInfo>
      </Header>

      <Stats>
        <Stat>
          <StatLabel>Capital</StatLabel>
          <StatValue>{formatUSD(amount)}</StatValue>
        </Stat>
        <Stat>
          <StatLabel>Start Date</StatLabel>
          <StatValue>{new Date(startDate).toLocaleDateString()}</StatValue>
        </Stat>
        <Stat>
          <StatLabel>End Date</StatLabel>
          <StatValue>{new Date(endDate).toLocaleDateString()}</StatValue>
        </Stat>
      </Stats>
      <Stats>
        <Stat>
          <StatLabel>Daily Profit</StatLabel>
          <StatValue>{formatUSD(Number(dailyProfit))}</StatValue> {/* Garante que é número */}
        </Stat>

        <Stat>
          <StatLabel>Accumulated Profit</StatLabel>
          <StatValue>{accumulatedProfitPercent}</StatValue>
        </Stat>
      </Stats>
      <ProgressBarContainer>
        {progress > 0 && <ProgressBar progress={progress} />}
      </ProgressBarContainer>
    </Card>
  );
};

export default InvestmentCard;
